@charset "UTF-8"; /*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.15),
    rgba(255, 255, 255, 0)
  );
}
*,
::after,
::before {
  box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}
hr:not([size]) {
  height: 1px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
.h1,
h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  .h1,
  h1 {
    font-size: 2.6rem;
  }
}
.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  .h2,
  h2 {
    font-size: 2rem;
  }
}
.h3,
h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  .h3,
  h3 {
    font-size: 1.75rem;
  }
}
.h4,
h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  .h4,
  h4 {
    font-size: 1.5rem;
  }
}
.h5,
h5 {
  font-size: 1.25rem;
}
.h6,
h6 {
  font-size: 1rem;
}
.h-offer {
  width: 635px;
  font-weight: 600;
  font-size: 40px;
  margin-top: 60px;
}
.h-titles {
  font-weight: 600;
  font-size: 55px;
  text-align: center;
  color: #09294f;
  margin-top: 10px;
  margin-bottom: 10px;
}
.h-titles-ciber {
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  color: #09294f;
  margin-top: 30px;
  padding-bottom: 10px;
}
.h-titles-ciber-SGSI {
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  color: #09294f;
  margin-top: 90px;
  padding-bottom: 10px;
}
.h-titles-ciber-SGSI2 {
  display: none;
}
.h-titles-attention1 {
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #09294f;
  margin-top: 30px;
  margin-left: -20px;
}
.h-titles-attention2 {
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  color: #000;
}
.h-titles-attention3 {
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #09294f;
}
.h-titles-attention4 {
  font-weight: 600;
  font-size: 45px;
  text-align: center;
  color: #000;
}
.title-form {
  width: 100%;
  height: 130px;
  font-weight: 600;
  padding-top: 40px;
  font-size: 40px;
  text-align: center;
  color: #fff;
  background-color: #09294f;
}
.title-form2 {
  width: 100%;
  height: 70px;
  font-weight: 600;
  padding-top: 10px;
  font-size: 40px;
  text-align: center;
  align-items: center;
  color: #fff;
  background-color: #09294f;
  margin-bottom: 10px;
}
.h-values-title {
  color: #0082c6;
  text-align: center;
  margin-top: 40px;
  font-size: 36px;
}
.h-values {
  color: #fff;
  text-align: center;
  margin-top: 50px;
  margin-left: 120px;
  font-size: 32px;
}
p {
  font-size: 1em;
}
.p-offer {
  margin-top: 0;
  font-size: 24px;
  text-align: justify;
}
.p-services {
  padding-top: 280px;
  text-align: justify;
  height: 410px;
}
.p-consult {
  margin-top: 20px;
  margin-right: 40px;
  font-size: 28px;
  text-align: justify;
  font-weight: 400;
}
.p-about-us {
  margin-top: 0;
  margin-bottom: 60px;
  margin-left: 50px;
  margin-right: 30px;
  font-size: 24px;
  text-align: justify;
}
.p-values {
  padding-top: 80px;
  margin-left: 30px;
  margin-right: 30px;
  text-align: justify;
}
.p-ciber {
  margin-top: 40px;
  margin-right: 40px;
  font-size: 23px;
  text-align: justify;
  font-weight: 400;
}
.p-ciber-specialized {
  width: 100%;
  margin-top: 40px;
  margin-right: 70px;
  margin-left: 200px;
  font-size: 23px;
  text-align: justify;
  font-weight: 400;
}
.p-ciber-quality {
  width: 100%;
  margin-top: 40px;
  margin-right: 70px;
  margin-left: 200px;
  font-size: 23px;
  text-align: justify;
  font-weight: 400;
}
.title-quality {
  margin-top: 20px;
  margin-right: 40px;
  margin-left: 40px;
  font-size: 28px;
  text-align: center;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}
a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0082c6;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}
figure {
  margin: 0 0 1rem;
}
img,
svg {
  vertical-align: middle;
}
table {
  caption-side: bottom;
  border-collapse: collapse;
}
caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}
th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}
label {
  display: inline-block;
}
button {
  border-radius: 0;
}
button:focus:not(:focus-visible) {
  outline: 0;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
select {
  text-transform: none;
}
[role="button"] {
  cursor: pointer;
}
select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}
[list]::-webkit-calendar-picker-indicator {
  display: none;
}
[type="button"],
[type="reset"],
[type="submit"],
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}
textarea {
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
output {
  display: inline-block;
}
.img-fluid {
  width: 500px;
  max-width: 100%;
  margin-left: 180px;
}
.img-logo {
  width: 230px;
}
.img-fluid-offer {
  width: 500px;
  max-width: 100%;
  margin-left: 180px;
}
.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  padding-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
}
.container-navbar {
  display: flex;
  align-items: center;
  width: 100%;
}
.container-carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.container-fluid-offer {
  width: 100%;
  height: 600px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-fluid-company {
  width: 100%;
  height: 500px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}
.container-fluid-values {
  width: 100%;
  height: 670px;
  padding-top: 30px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  background-color: #d9d9d9;
}
.container-fluid-mission-vision {
  width: 100%;
  height: 650px;
  padding-top: 30px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.container-fluid-services-sp {
  width: 100%;
  height: 650px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
}
.container-fluid-infra {
  width: 100%;
  height: 670px;
  padding-top: 30px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  background-color: #d9d9d9;
}
.container-fluid-innova {
  width: 100%;
  height: 615px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
}
.container-fluid-networks {
  width: 100%;
  height: 600px;
  padding-top: 30px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  background-color: #d9d9d9;
}
.container-fluid-air {
  width: 100%;
  height: 615px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 50px;
}
.container-fluid-ciber-SGSI {
  width: 100%;
  height: 860px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
}
.container-fluid-ciber-specialized {
  width: 100%;
  height: 1000px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
}
.container-fluid-ciber-quality {
  width: 100%;
  height: 950px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
}
.container-fluid-services-form {
  width: 100%;
  height: 550px;
  margin-right: auto;
  margin-left: auto;
  background-color: #d9d9d9;
}
.container-fluid-attention {
  width: 100%;
  height: 500px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  padding-top: 10px;
  padding-bottom: 40px;
}
.container-fluid-contact-form {
  width: 100%;
  height: 400px;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  background-color: #09294f;
  padding-bottom: 30px;
}
.container-fluid-contact-form {
  width: 100%;
  height: 500px;
  margin-right: auto;
  margin-left: auto;
  background-color: #d9d9d9;
}
.container-fluid-policy {
  width: 100%;
  height: auto;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 80px;
}
.container-lines {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  align-items: center;
}
.container-map {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.container-list-politics {
  padding-inline-start: 20px;
}
.links-politics {
  color: #0082c6;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
  .img-fluid-offer {
    width: 100%;
  }
}
@media only screen and (max-width: 480px) {
  img {
    width: 100%;
  }
}
@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}
@media (max-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
  .h-offer {
    font-size: 2em;
  }
}
@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: 50px;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}
.row-value-offer {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  margin-top: 30px;
}
.row-services {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  margin-right: 20px;
  margin-left: 50px;
}
.row-about-us {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 80px;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-left: 300px;
}
.row-company {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-direction: row;
  margin-top: 100px;
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-left: 100px;
  padding-right: 100px;
}
.row-values {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
}
.row-m-v {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  margin-bottom: 30px;
}
.row-container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: space-evenly;
}
.row-consult {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 100px;
  flex-shrink: 0;
  max-width: 100%;
  padding-right: 100px;
}
.row-services-portfolio {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  width: 100%;
  max-width: 100%;
  padding-right: 50px;
  padding-left: 50px;
  justify-content: space-evenly;
}
.row-services-portfolio2 {
  display: none;
}
.row-services-portfolio3 {
  display: none;
}
.row-ciber {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-right: 100px;
  margin-left: 100px;
  flex-shrink: 0;
  max-width: 100%;
  margin-bottom: 10px;
}
.row-ciber-specialized {
  display: flex;
  flex-wrap: wrap;
  margin-right: 115px;
  margin-left: 100px;
  flex-shrink: 0;
  max-width: 100%;
}
.row-ciber-quality {
  display: flex;
  flex-wrap: wrap;
  margin-right: 115px;
  margin-left: 100px;
  flex-shrink: 0;
  max-width: 100%;
}
.row-ciber-quality2 {
  display: none;
}
.row-attention {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-left: 500px;
}
.services-link-infra {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  font-size: 34px;
  font-weight: 500;
}
.services-link-innova {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  font-size: 34px;
  font-weight: 500;
  margin-left: 20px;
}
.services-link-net {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  font-size: 34px;
  font-weight: 500;
  margin-left: 33px;
}
.services-link-air {
  display: block;
  padding: 0.5rem 1rem;
  color: #000;
  text-decoration: none;
  font-size: 34px;
  font-weight: 500;
  margin-left: -10px;
}
.img-services {
  width: 210px;
}
.img-infra {
  width: 350px;
}
.img-innova {
  width: 350px;
}
.img-ciber {
  width: 400px;
}
.service-item {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  text-align: center;
  margin-top: 40px;
}
.services-sp-containers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.row-infraestructure {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 200px;
}
.row-infraestructure {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 200px;
}
.row-innovation {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 200px;
}
.row-innovation2 {
  display: none;
}
.list-infraestructure-services {
  padding-top: 30px;
  padding-left: 150px;
  font-size: 24px;
  list-style-type: "✓ ";
}
.list-innovation-services {
  padding-top: 60px;
  padding-right: 100px;
  padding-left: 100px;
  font-size: 24px;
  list-style-type: "✓ ";
}
.list-networks-services {
  padding-top: 50px;
  padding-left: 150px;
  padding-right: 100px;
  font-size: 24px;
  list-style-type: "✓ ";
}
.list-air-services {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 100px;
  font-size: 24px;
  list-style-type: "✓ ";
}
ul li::marker {
  color: #0082c6;
  font-weight: 600;
}
.col {
  flex: 1 0 0%;
}
.col-offer {
  flex: 1 0 0%;
  margin-left: 50px;
  margin-right: 50px;
}
.col-text-offer {
  flex: 1 0 0%;
  margin-left: 50px;
  margin-right: 160px;
}
.col-company {
  flex: 1 0;
  margin-top: 30px;
  margin-left: 100px;
  margin-right: 30px;
  width: 500px;
}
.col-service1 {
  flex: 1 0;
  width: 100vw;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/services/services1.1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-service2 {
  flex: 1 0;
  width: 100vw;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/services/services2.1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-service3 {
  flex: 1 0;
  width: 100vw;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/services/services3.1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-img-consult {
  width: 600px;
  padding-right: 100px;
  padding-left: 100px;
}
.img-consult {
  width: 400px;
}
.img-company {
  width: 500px;
  margin-top: 20px;
  margin-left: 80px;
}
.col-img-ciber {
  flex: 0 0 auto;
  width: auto;
  padding-top: 50px;
  padding-right: 130px;
  padding-left: 90px;
}
.col-value1 {
  flex: 1 0;
  width: 100vw;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/about-us/valor1.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-value2 {
  flex: 1 0;
  width: 100vw;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/about-us/valor2.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-value3 {
  flex: 1 0;
  width: 100vw;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../public/images/about-us/valor3.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.col-mission {
  flex: 1 0;
  width: 100vw;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-vision {
  flex: 1 0;
  width: 100vw;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.col-icons {
  flex: 0 0 auto;
  width: 245px;
  height: 250px;
  display: flex;
  border: 12px solid #09294f;
  border-radius: 120px;
  align-items: center;
  justify-content: center;
}
.col-img-infra {
  flex: 0 0 auto;
  width: auto;
  padding-top: 30px;
  padding-left: 80px;
}
.col-img-innova {
  flex: 0 0 auto;
  width: auto;
  padding-top: 20px;
  padding-right: 150px;
}
.col-quality {
  flex: 1 0;
  width: 100vw;
  height: 600px;
  display: flex;
  margin-top: -30px;
}
.col-img-quality {
  flex: 0 0 auto;
  width: auto;
  padding-top: 50px;
  padding-right: 150px;
}
.slider1 {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider1-fondo.png") no-repeat;
  background-size: cover;
  background-position: unset;
}
.slider2 {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider2-fondo.png") no-repeat center
    center;
  background-size: cover;
  background-position: unset;
}
.slider3 {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider3-fondo.png") no-repeat center
    center;
  background-size: cover;
  background-position: unset;
}
.container-slider-about-us {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider-nosotros-fondo.png")
    no-repeat;
  background-size: cover;
  background-position: unset;
}
.container-slider-services {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider-servicios-fondo.png")
    no-repeat;
  background-size: cover;
  background-position: unset;
}
.container-slider-cybersecurity {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider-ciberseguridad-fondoOK.png")
    no-repeat;
  background-size: cover;
  background-position: unset;
}
.container-slider-blog {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider-blog-fondo.png") no-repeat;
  background-size: cover;
  background-position: unset;
}
.container-slider-contact {
  flex: 1 0;
  width: 100%;
  height: 670px;
  display: flex;
  align-items: center;
  background: url("../public/images/sliders/slider-ciberseguridad-fondoOK.png")
    no-repeat;
  background-size: cover;
  background-position: unset;
}
.img-slider-home {
  width: 650px;
  margin-top: 50px;
  margin-left: 320px;
}
.img-slider-pages {
  width: 610px;
  margin-top: 70px;
  margin-left: 300px;
}
.col-slider-home {
  height: 100%;
  width: 60%;
}
.col-slider-pages {
  height: 100%;
  width: 80%;
}
.col-icons-container {
  display: flex;
  flex-direction: row;
}
.img-icon {
  display: flex;
  flex-direction: column;
}
.col-location {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
}
.col-icon-map {
  flex: 0 0 auto;
  width: auto;
}
.col-icon-map2 {
  display: none;
}
.img-map {
  margin-left: 40px;
  width: 180px;
}
.icon {
  flex: 0 0 auto;
  width: 70px;
  height: 70px;
  margin-top: 20px;
}
.col-lines {
  flex: 1 0;
  width: 100%;
  align-items: center;
}
.col-icons-contact {
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-left: 20px;
  color: #000;
}
.wpp-contact {
  flex: 0 0 auto;
  width: auto;
  margin-top: 10px;
  margin-bottom: 50px;
  font-size: 32px;
}
.mail-contact {
  font-size: 32px;
}
#contact-titles {
  text-align: left;
  margin-bottom: 10px;
  color: #09294f;
  font-size: 23px;
}
.pages {
  margin-top: 20px;
}
.container-diagnostic-form {
  background-color: white;
  width: 1166px;
  height: 200px;
  border-radius: 30px;
  margin-left: 380px;
}
.soon1 {
  display: flex;
  flex-direction: row;
  text-align: justify;
  font-size: 32px;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
  align-items: center;
  justify-content: center;
}
.soon2 {
  margin-top: 20px;
  text-align: center;
  font-size: 32px;
}
.container-content-policy {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}
.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}
.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}
.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}
.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}
.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}
.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}
@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
}
.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}
.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}
.col-5 {
  width: 41.66666667%;
}
.col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.col-7 {
  width: 58.33333333%;
}
.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}
.col-9 {
  flex: 0 0 auto;
  width: 75%;
}
.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}
.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}
.col-12 {
  flex: 0 0 auto;
  width: 100%;
}
@media (min-width: 576px) {
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
}
@media (min-width: 768px) {
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
}
.contact-form {
  max-width: 700px;
  text-align: center;
  margin: 20px auto;
  margin-top: 70px;
}
input,
textarea {
  border: 0;
  outline: 0;
  padding: 1em;
  border-radius: 8px;
  display: block;
  width: 100%;
  resize: none;
  font-size: 16px;
}
.input2 {
  display: none;
}
#input-submit {
  cursor: pointer;
  color: white;
  background-color: #0082c6;
  font-weight: 500;
  font-size: 19px;
  width: 687px;
  height: 60px;
}
#input-submit:hover {
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
}
textarea {
  height: 172px;
}
.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}
.right {
  width: 50%;
}
.left {
  margin-right: 2%;
}
@media (max-width: 480px) {
  .half {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }
}
.cf:before,
.cf:after {
  content: " ";
  display: table;
}
.cf:after {
  clear: both;
}
.form-label {
  margin-bottom: 0.5rem;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}
.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  -webkit-margin-end: 0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  -webkit-margin-end: 0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
  margin-inline-end: 1rem;
}
textarea.form-control {
  min-height: calc(1.5em + (0.75rem + 2px));
}
textarea.form-control-sm {
  min-height: calc(1.5em + (0.5rem + 2px));
}
textarea.form-control-lg {
  min-height: calc(1.5em + (1rem + 2px));
}
.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}
.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}
.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}
.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}
.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input:disabled ~ .form-check-label,
.form-check-input[disabled] ~ .form-check-label {
  opacity: 0.5;
}
.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}
.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check:disabled + .btn,
.btn-check[disabled] + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}
.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-lg > .btn,
.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)
  > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation
  > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip,
.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip {
  display: block;
}
.form-control.is-valid,
.was-validated .form-control:valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-valid,
.was-validated .form-select:valid {
  border-color: #198754;
}
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-valid:focus,
.was-validated .form-select:valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid,
.was-validated .form-check-input:valid {
  border-color: #198754;
}
.form-check-input.is-valid:checked,
.was-validated .form-check-input:valid:checked {
  background-color: #198754;
}
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.form-check-input.is-valid ~ .form-check-label,
.was-validated .form-check-input:valid ~ .form-check-label {
  color: #198754;
}
.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-valid,
.input-group .form-select.is-valid,
.was-validated .input-group .form-control:valid,
.was-validated .input-group .form-select:valid {
  z-index: 1;
}
.input-group .form-control.is-valid:focus,
.input-group .form-select.is-valid:focus,
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .form-select:valid:focus {
  z-index: 3;
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip {
  display: block;
}
.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right
    calc(0.375em + 0.1875rem);
}
.form-select.is-invalid,
.was-validated .form-select:invalid {
  border-color: #dc3545;
}
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-select.is-invalid:focus,
.was-validated .form-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid,
.was-validated .form-check-input:invalid {
  border-color: #dc3545;
}
.form-check-input.is-invalid:checked,
.was-validated .form-check-input:invalid:checked {
  background-color: #dc3545;
}
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.form-check-input.is-invalid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545;
}
.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}
.input-group .form-control.is-invalid,
.input-group .form-select.is-invalid,
.was-validated .input-group .form-control:invalid,
.was-validated .input-group .form-select:invalid {
  z-index: 2;
}
.input-group .form-control.is-invalid:focus,
.input-group .form-select.is-invalid:focus,
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .form-select:invalid:focus {
  z-index: 3;
}
.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1;
  color: white;
  background-color: #0082c6;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 5px;
  font-size: 17px;
  border-radius: 0.9rem;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #fff;
}

.btn-outline-primary {
  color: #ffffff;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:active + .btn-outline-primary,
.btn-check:checked + .btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:active + .btn-outline-primary:focus,
.btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #fff;
  background-color: #0082c6;
}
.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:active + .btn-outline-secondary,
.btn-check:checked + .btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #0082c6;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:active + .btn-outline-secondary:focus,
.btn-check:checked + .btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-success {
  color: white;
  background-color: #0082c6;
  margin-left: 4px;
  margin-right: 20px;
  margin-top: 5px;
  width: 125px;
  height: 35px;
  font-weight: 500;
  font-size: 19px;
  padding-top: 7px;
  padding-right: 10px;
}
.btn-outline-success-2 {
  color: white;
  background-color: #0082c6;
  margin-left: 4px;
  margin-right: 20px;
  margin-top: 5px;
  width: 125px;
  height: 35px;
  font-weight: 500;
  font-size: 19px;
  padding-top: 7px;
  padding-right: 10px;
}
.btn-slider {
  color: white;
  background-color: #0082c6;
  font-weight: 500;
  font-size: 20px;
  width: 200px;
  height: 40px;
  padding-top: 10px;
  position: absolute;
  top: 500px;
  left: 200px;
}
.btn-slider:hover {
  color: #0082c6;
  background-color: white;
  border-color: #0082c6;
}
.btn-offer {
  color: white;
  background-color: #0082c6;
  font-weight: 500;
  font-size: 19px;
  width: 250px;
  height: 40px;
  margin-top: 30px;
  padding-top: 10px;
}
.btn-consult {
  color: white;
  background-color: #0082c6;
  font-weight: 500;
  font-size: 19px;
  width: 250px;
  height: 40px;
  margin-top: 30px;
  padding-top: 10px;
}
.btn-diagnostic-form {
  color: white;
  background-color: #0082c6;
  font-weight: 500;
  font-size: 24px;
  width: 300px;
  height: 50px;
  margin-top: 70px;
  padding-top: 10px;
  margin-left: 860px;
}
.btn-offer:hover {
  color: #0082c6;
  background-color: white;
  border-color: #0082c6;
}
.btn-outline-success:hover {
  color: #0082c6;
  background-color: white;
  border-color: #0082c6;
}
.btn-outline-success-2:hover {
  color: #0082c6;
  background-color: white;
  border-color: #0082c6;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem #0082c6;
}
.btn-check:active + .btn-outline-success,
.btn-check:checked + .btn-outline-success,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show,
.btn-outline-success:active {
  color: #fff;
  background-color: #0082c6;
  border-color: #0082c6;
}
.btn-check:active + .btn-outline-success:focus,
.btn-check:checked + .btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: 0 0 0 0.25rem #0082c6;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:active + .btn-outline-info,
.btn-check:checked + .btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:active + .btn-outline-info:focus,
.btn-check:checked + .btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dcaf0;
  background-color: transparent;
}
.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:active + .btn-outline-warning,
.btn-check:checked + .btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:active + .btn-outline-warning:focus,
.btn-check:checked + .btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:active + .btn-outline-danger,
.btn-check:checked + .btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:active + .btn-outline-danger:focus,
.btn-check:checked + .btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:active + .btn-outline-light,
.btn-check:checked + .btn-outline-light,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show,
.btn-outline-light:active {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:active + .btn-outline-light:focus,
.btn-check:checked + .btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:active + .btn-outline-dark,
.btn-check:checked + .btn-outline-dark,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show,
.btn-outline-dark:active {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:active + .btn-outline-dark:focus,
.btn-check:checked + .btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #212529;
  background-color: transparent;
}
.btn-link {
  margin-top: -30px;
  margin-left: 200px;
  font-weight: 500;
  color: black;
  text-align: center;
  justify-content: end;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 17px;
  border-radius: 0.5rem;
  background-color: #fff;
}
.btn-link:hover {
  color: #ffffff;
  background-color: #0082c6;
}
.btn-link.disabled,
.btn-link:disabled {
  color: #6c757d;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.nav-link {
  display: block;
  padding-top: 7px;
  padding-left: 15px;
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}
.nav-link:hover {
  color: #0082c6;
}
.header {
  position: sticky;
  top: 0;
  backdrop-filter: blur(10px);
  margin: 0;
  z-index: 1;
}
.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-menu {
  display: flex;
  margin-bottom: 0;
  list-style: none;
  font-size: 20px;
  width: 100%;
  max-width: 1920px;
  justify-content: flex-end;
  gap: 10px;
}
.navbar-menu2 {
  display: none;
}
.nav-toggle {
  color: white;
  background: none;
  border: none;
  font-size: 30px;
  padding: 0 20px;
  line-height: 60px;
  display: none;
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: right;
}
.navbar-menu-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}
.container-footer {
  width: 100%;
  height: 220px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #09294f;
}
.social-networks {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
}
.social-networks-hidden {
  display: none;
}
.container-politics {
  display: none;
}
.icons-social-networks {
  width: 50px;
}
.icons-social-networks2 {
  width: 50px;
}
.content-foo {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  text-align: start;
}
.content-foo-legal {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  text-align: start;
}
.content-foo-social {
  display: flex;
  flex-direction: column;
  text-align: start;
}
.content-foo h4 {
  color: #fff;
  border-bottom: 3px solid #fff;
  text-align: center;
}
.content-foo-legal h4 {
  color: #fff;
  border-bottom: 3px solid #fff;
  text-align: center;
}
.content-foo-social h4 {
  color: #fff;
  border-bottom: 3px solid #fff;
  text-align: center;
}
.content-foo p {
  color: #fff;
}
.content-foo-social p {
  color: #fff;
}
.content-foo2 {
  display: flex;
  margin-top: -50px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  justify-content: space-evenly;
  background-color: #dad9d9;
}
.footer-icon {
  flex: 0 0 auto;
  width: 50px;
  height: 50px;
}
.footer-copyright {
  border-top: 4px solid #09294f;
  text-align: center;
  margin: 50px 0 30px 0;
  color: #09294f;
}
.text-bold {
  font-weight: 500;
}
.text-bold-title {
  font-weight: 700;
}
.container-post {
  height: auto;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.container-politicy {
  margin: 200px;
  margin-top: 70px;
}
.h-titles-policy {
  font-weight: 600;
  font-size: 54px;
  text-align: center;
  color: #09294f;
  margin-bottom: 10px;
  margin-top: 100px;
}
table {
  font-family: var(--bs-font-sans-serif);
  font-size: 18px;
  text-align: left;
  width: 800px;
}
th {
  background-color: #d9d9d9;
  border: 1px solid black;
  padding: 5px;
}
td {
  border: 1px solid black;
  padding: 5px;
}

.wave-slider {
  position: relative;
}
.wave-slider2 {
  display: none;
}
.wave-slider3 {
  display: none;
}
.wave-slider4 {
  display: none;
}
.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}
.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}
.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}
.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}
.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}
.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}
.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}
.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}
.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}
@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close.disabled,
.btn-close:disabled {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  opacity: 0.25;
}
.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}
.carousel-current-clients {
  display: flex;
  position: relative;
  width: 100%;
  position: relative;
  margin-top: 40px;
  overflow: hidden;
  padding: 2.5rem;
  align-items: center;
}
.carousel-satisfied-clients {
  display: flex;
  position: relative;
  width: 100%;
  position: relative;
  margin-top: 40px;
  overflow: hidden;
  padding: 2.5rem;
  align-items: center;
}
.companies {
  animation: animate 50s linear infinite alternate;
}
.carousel-current-clients:hover .companies {
  animation-play-state: paused;
}
.carousel-satisfied-clients:hover .companies {
  animation-play-state: paused;
}
.companies img {
  min-width: 200px;
  padding: 0 30px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.companies img:hover {
  filter: none;
}

@keyframes animate {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-3050px, 0, 0);
  }
}
.d-block {
  display: block !important;
}
.w-100 {
  width: 100% !important;
}
.carousel.pointer-event {
  touch-action: pan-y;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}
.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
/*.carousel-item2 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  background: url(C:\Web-Soluproint\public\images\sliders\slider2.png) no-repeat center center;
}
.carousel-item3 {
  position: relative;
  display: block;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
  background: url(C:\Web-Soluproint\ReactSp\public\images\sliders\slider3.png) no-repeat center center;
}*/
@media (prefers-reduced-motion: reduce) {
  .carousel-item2 {
    transition: none;
  }
  .carousel-item3 {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}
.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%);
}
.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%);
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    transition: none;
  }
}
.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 400px;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14%;
  height: 100px;
  padding: 0;
  color: #000;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    transition: none;
  }
}
.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("../public/images/icons/flecha-izquierda-slider.png");
}
.carousel-control-next-icon {
  background-image: url("../public/images/icons/flecha-derecha-slider.png");
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}
.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}
@-webkit-keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.border {
  border: 2px solid #09294f !important;
}
.border-0 {
  border: 0 !important;
}
.border-1 {
  border-width: 1px !important;
}
.border-2 {
  width: 35% !important;
  margin-top: -40px;
}
.border-3 {
  margin-left: 65%;
  margin-top: -20px;
}
.border-4 {
  width: 36%;
  margin-top: -40px;
}
.border-5 {
  margin-left: 64%;
  margin-top: -20px;
}
.border-6 {
  width: 42%;
  margin-top: -40px;
}
.border-7 {
  margin-left: 58%;
  margin-top: -20px;
}
.border-8 {
  width: 37%;
  margin-top: -40px;
}
.border-9 {
  margin-left: 63%;
  margin-top: -20px;
}
.border-10 {
  width: 30%;
  margin-top: -40px;
}
.border-11 {
  margin-left: 70%;
  margin-top: -20px;
}
.border-12 {
  width: 22%;
  margin-top: -40px;
}
.border-13 {
  margin-left: 78%;
  margin-top: -20px;
}
.border-14 {
  width: 38%;
  margin-top: -40px;
}
.border-15 {
  margin-left: 62%;
  margin-top: -20px;
}
.border-16 {
  width: 41%;
  margin-top: -40px;
}
.border-17 {
  margin-left: 59%;
  margin-top: -20px;
}
.border-18 {
  width: 28%;
  margin-top: -40px;
}
.border-19 {
  margin-left: 72%;
  margin-top: -20px;
}
.border-slider-home {
  border: 4px solid #09294f;
  border-color: #09294f;
  opacity: 1;
  margin-left: 200px;
  margin-top: 10px;
  width: 395px;
}
.border-slider-about {
  border: 4px solid #0082c6;
  border-color: #0082c6;
  opacity: 1;
  margin-left: 80px;
  margin-top: 10px;
  width: 670px;
}
.border-slider-services {
  border: 4px solid #0082c6;
  border-color: #0082c6;
  opacity: 1;
  margin-left: 80px;
  margin-top: 10px;
  width: 635px;
}
.border-slider-ciber {
  border: 4px solid #0082c6;
  border-color: #0082c6;
  opacity: 1;
  margin-left: 80px;
  margin-top: 10px;
  width: 760px;
}
.border-slider-blog {
  border: 4px solid #0082c6;
  border-color: #0082c6;
  opacity: 1;
  margin-left: 80px;
  margin-top: 10px;
  width: 375px;
}
.border-slider-contact {
  border: 4px solid #0082c6;
  border-color: #0082c6;
  opacity: 1;
  margin-left: 80px;
  margin-top: 10px;
  width: 495px;
}
.border-primary {
  color: #09294f;
  border-color: #09294f;
  opacity: 1;
}
.text-wrap {
  font-size: 20px;
  text-align: left;
  width: 50%;
  height: 100%;
  font-weight: 500;
}
.text-wrap-slider {
  font-size: 20px;
  text-align: left;
  width: 40%;
  height: 100%;
  font-weight: 500;
}
.text-wrap-slider-pages {
  font-size: 20px;
  text-align: left;
  width: 70%;
  height: 100%;
  font-weight: 500;
}
.text-wrap-slider .title-slider-home {
  font-size: 35px;
  margin-top: 250px;
  margin-left: 200px;
  margin-right: 30px;
  text-align: left;
  color: #09294f;
  font-weight: bold;
}
.text-wrap-slider .title-slider-home3 {
  font-size: 35px;
  margin-top: 210px;
  margin-left: 200px;
  margin-right: 30px;
  text-align: left;
  color: #09294f;
  font-weight: bold;
}
.text-wrap-slider-pages .title-slider-pages {
  font-size: 35px;
  margin-top: 250px;
  margin-left: 80px;
  margin-right: 30px;
  text-align: left;
  color: #fff;
  font-weight: bold;
}
.text-wrap-slider .p-slider-home {
  font-size: 23px;
  margin-top: 20px;
  margin-left: 200px;
  color: #000;
  font-weight: 500;
}
.text-wrap-slider .p-slider-home2 {
  display: none;
}
.text-wrap-service {
  font-size: 20px;
  width: 310px;
  height: 100%;
  font-weight: 500;
  margin-top: 60px;
}
.text-wrap-values {
  font-size: 20px;
  text-align: left;
  width: 73%;
  height: 100%;
  font-weight: 500;
}
.text-wrap-mission {
  margin-top: 0px;
  width: 600px;
  text-align: justify;
  font-size: 20px;
  height: 500px;
  font-weight: 500;
  background-image: url("../public/images/about-us/mision.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.text-wrap-vision {
  margin-top: 0px;
  width: 600px;
  text-align: justify;
  font-size: 20px;
  height: 500px;
  font-weight: 500;
  background-image: url("../public/images/about-us/vision.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.text-wrap-mission .values {
  margin-top: 215px;
  font-size: 38px;
  font-weight: 500;
  text-align: center;
}
.text-wrap-vision .values {
  margin-top: 215px;
  font-size: 38px;
  font-weight: 500;
  text-align: center;
}
.text-wrap-mission .p-m-v {
  margin-top: 40px;
  width: 530px;
  margin-left: 30px;
}
.text-wrap-vision .p-m-v {
  margin-top: 40px;
  width: 530px;
  margin-left: 30px;
}
.text-wrap-slider .title-slider {
  font-size: 35px;
  margin-top: 250px;
  margin-left: 20px;
  margin-right: 30px;
  text-align: left;
  color: #fff;
}
.text-wrap-slider-pages .p-slider-pages {
  font-size: 23px;
  margin-top: 20px;
  margin-left: 80px;
  color: #fff;
  font-weight: 500;
}
.bg-nav-sp {
  background-color: #09294f !important;
  height: 90px;
}
.p-content-policy {
  font-size: 23px;
  width: auto;
  text-align: justify;
}

@media (max-width: 1870px) {
  .img-slider-home {
    width: 650px;
    margin-top: 50px;
    margin-left: 280px;
  }
}
@media (max-width: 1770px) {
  .img-slider-home {
    width: 650px;
    margin-top: 50px;
    margin-left: 230px;
  }
}
@media (max-width: 1731px) {
  .img-slider-pages {
    margin-left: 250px;
  }
}
@media (max-width: 1678px) {
  .container-fluid-ciber-SGSI {
    height: 900px;
  }
}
@media (max-width: 1653px) {
  .img-fluid-offer {
    width: 500px;
    max-width: 100%;
    margin-left: 150px;
  }
  .h-offer {
    width: 555px;
    font-weight: 600;
    font-size: 35px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 22px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 80px;
    margin-right: 130px;
  }
  .h-titles {
    font-size: 55px;
  }
  .h-titles-ciber {
    font-size: 50px;
  }
  .h-titles-ciber-SGSI {
    font-size: 50px;
  }
  .border-2 {
    width: 33% !important;
    margin-top: -40px;
  }
  .border-3 {
    margin-left: 67%;
    margin-top: -20px;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 80px;
    margin-left: 80px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 80px;
  }
  .col-img-consult {
    width: 600px;
    padding-right: 100px;
    padding-left: 100px;
  }
  .img-consult {
    width: 400px;
  }
  .p-consult {
    margin-top: 20px;
    margin-right: 40px;
    font-size: 26px;
    text-align: justify;
    font-weight: 400;
  }
  .border-8 {
    width: 34%;
    margin-top: -40px;
  }
  .border-9 {
    margin-left: 66%;
    margin-top: -20px;
  }
  .row-about-us {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-left: 200px;
  }
  .col-company {
    margin-right: 0;
  }
  .img-company {
    width: 450px;
    margin-top: 40px;
  }
  .p-about-us {
    margin-top: 0;
    margin-bottom: 60px;
    margin-left: 45px;
    margin-right: 25px;
    font-size: 23px;
    text-align: justify;
  }
  .container-fluid-infra {
    height: 660px;
  }
  .row-infraestructure {
    margin-right: 90px;
    margin-left: 190px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 140px;
    font-size: 23px;
    list-style-type: "✓ ";
  }
  .border-14 {
    width: 37%;
    margin-top: -40px;
  }
  .border-15 {
    margin-left: 63%;
    margin-top: -20px;
  }
  .border-16 {
    width: 40%;
    margin-top: -40px;
  }
  .border-17 {
    margin-left: 60%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 635px;
  }
  .row-innovation {
    margin-right: 90px;
    margin-left: 190px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 90px;
    padding-left: 50px;
    font-size: 23px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 100px;
  }
  .border-10 {
    width: 28%;
    margin-top: -40px;
  }
  .border-11 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .list-networks-services {
    padding-top: 50px;
    padding-left: 140px;
    padding-right: 90px;
    font-size: 23px;
  }
  .border-12 {
    width: 19%;
    margin-top: -40px;
  }
  .border-13 {
    margin-left: 81%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 635px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 90px;
    padding-left: 50px;
    font-size: 23px;
  }
  .border-18 {
    width: 27%;
    margin-top: -40px;
  }
  .border-19 {
    margin-left: 73%;
    margin-top: -20px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 90px;
    margin-left: 90px;
    margin-bottom: 10px;
  }
  .container-fluid-ciber-specialized {
    height: 1050px;
  }
  .container-fluid-ciber-quality {
    height: 980px;
  }
  .col-img-ciber {
    padding-top: 50px;
    padding-right: 100px;
    padding-left: 70px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 28px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 50px;
    padding-right: 130px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 450px;
  }
  .img-slider-home {
    width: 650px;
    margin-top: 50px;
    margin-left: 180px;
  }
  .img-slider-pages {
    margin-left: 200px;
  }
  .container-diagnostic-form {
    margin-left: 250px;
  }
}

@media (max-width: 1871px) {
  .border-slider-ciber {
    width: 500px;
  }
}
@media (max-width: 1691px) {
  .border-slider-about {
    width: 470px;
  }
}
@media (max-width: 1614px) {
  .border-slider-services {
    width: 480px;
  }
  .h-titles-policy {
    font-size: 50px;
  }
  .title-policy {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .p-content-policy {
    font-size: 20px;
  }
  .container-politicy {
    margin: 150px;
    margin-top: 50px;
  }
}
@media (max-width: 1580px) {
  .border-18 {
    width: 26%;
    margin-top: -40px;
  }
  .border-19 {
    margin-left: 74%;
    margin-top: -20px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 30px;
    font-size: 22px;
  }
  .container-fluid-ciber-specialized {
    height: 1030px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 80px;
    margin-left: 80px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 70px;
    margin-left: 150px;
    font-size: 22px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 70px;
    margin-left: 200px;
    font-size: 22px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 80px;
    margin-left: 80px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 27px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 50px;
    padding-right: 100px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 400px;
  }
  .img-slider-home {
    width: 650px;
    margin-top: 50px;
    margin-left: 100px;
  }
}
@media (max-width: 1568px) {
  .border-slider-home {
    width: 290px;
  }
}
@media (max-width: 1550px) {
  .carousel-control-next,
  .carousel-control-prev {
    width: 10%;
  }
}
@media (max-width: 1517px) {
  .img-fluid-offer {
    width: 450px;
    max-width: 100%;
    margin-left: 120px;
  }
  .h-offer {
    width: 475px;
    font-weight: 600;
    font-size: 30px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 20px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 50px;
    margin-right: 100px;
  }
  .h-titles {
    font-size: 52px;
  }
  .h-titles-ciber {
    font-size: 47px;
  }
  .h-titles-ciber-SGSI {
    font-size: 47px;
  }
  .border-2 {
    width: 33% !important;
    margin-top: -40px;
  }
  .border-3 {
    margin-left: 67%;
    margin-top: -20px;
  }
  .col-img-consult {
    width: 500px;
    padding-right: 80px;
    padding-left: 80px;
  }
  .img-consult {
    width: 330px;
  }
  .p-consult {
    margin-top: 20px;
    margin-right: 0px;
    font-size: 25px;
    text-align: justify;
    font-weight: 400;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 18px;
    width: 230px;
    height: 35px;
    margin-top: 15px;
    padding-top: 5px;
  }
  .border-4 {
    width: 34%;
    margin-top: -40px;
  }
  .border-5 {
    margin-left: 66%;
    margin-top: -20px;
  }
  .text-wrap-values {
    font-size: 19px;
    text-align: left;
    width: 80%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-left: 120px;
    font-size: 31px;
  }
  .p-values {
    padding-top: 80px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .container-fluid-infra {
    height: 640px;
  }
  .row-infraestructure {
    margin-right: 70px;
    margin-left: 180px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 140px;
    font-size: 22px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 70px;
  }
  .img-infra {
    width: 330px;
  }
  .container-fluid-innova {
    height: 625px;
  }
  .row-innovation {
    margin-right: 70px;
    margin-left: 180px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 90px;
    padding-left: 50px;
    font-size: 22px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 80px;
  }
  .img-innova {
    width: 330px;
  }
  .list-networks-services {
    padding-top: 50px;
    padding-left: 140px;
    padding-right: 70px;
    font-size: 22px;
  }
  .container-fluid-air {
    height: 625px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 90px;
    padding-left: 50px;
    font-size: 22px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 80px;
    margin-left: 80px;
    margin-bottom: 10px;
  }
  .img-ciber {
    width: 380px;
  }
  .col-img-ciber {
    padding-top: 50px;
    padding-right: 100px;
    padding-left: 60px;
  }
  .title-form {
    padding-top: 40px;
    font-size: 36px;
  }
  .title-form2 {
    padding-top: 10px;
    font-size: 36px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 30px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 350px;
  }
  .container-diagnostic-form {
    margin-left: 170px;
  }
}
@media (max-width: 1510px) {
  .img-slider-pages {
    margin-left: 150px;
  }
}
@media (max-width: 1490px) {
  .row-company {
    padding-left: 80px;
    padding-right: 80px;
  }
  .col-company {
    margin-left: 80px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 35px;
  }
  .text-wrap-values {
    font-size: 18px;
    text-align: left;
    width: 80%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-left: 120px;
    font-size: 30px;
  }
  .p-values {
    padding-top: 70px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 370px;
  }
  .col-value2 {
    height: 370px;
  }
  .col-value3 {
    height: 370px;
  }
  .container-fluid-infra {
    height: 620px;
  }
  .row-infraestructure {
    margin-right: 60px;
    margin-left: 170px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 120px;
    font-size: 22px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 60px;
  }
  .img-infra {
    width: 300px;
  }
  .border-14 {
    width: 36%;
    margin-top: -40px;
  }
  .border-15 {
    margin-left: 64%;
    margin-top: -20px;
  }
  .border-16 {
    width: 39%;
    margin-top: -36px;
  }
  .border-17 {
    margin-left: 61%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 605px;
  }
  .row-innovation {
    margin-right: 60px;
    margin-left: 170px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 80px;
    padding-left: 50px;
    font-size: 22px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 70px;
  }
  .img-innova {
    width: 300px;
  }
  .border-10 {
    width: 27%;
    margin-top: -40px;
  }
  .border-11 {
    margin-left: 73%;
    margin-top: -20px;
  }
  .list-networks-services {
    padding-top: 50px;
    padding-left: 120px;
    padding-right: 60px;
    font-size: 22px;
  }
  .border-12 {
    width: 18%;
    margin-top: -38px;
  }
  .border-13 {
    margin-left: 82%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 605px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 80px;
    padding-left: 50px;
    font-size: 22px;
  }
  .border-18 {
    width: 25%;
    margin-top: -36px;
  }
  .border-19 {
    margin-left: 75%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 850px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 70px;
    margin-left: 70px;
    margin-bottom: 10px;
  }
  .img-ciber {
    width: 350px;
  }
  .col-img-ciber {
    padding-top: 70px;
    padding-right: 100px;
    padding-left: 50px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 20px;
    font-size: 21px;
  }
  .container-fluid-ciber-specialized {
    height: 1060px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 70px;
    margin-left: 70px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 60px;
    margin-left: 150px;
    font-size: 22px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 60px;
    margin-left: 180px;
    font-size: 22px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 70px;
    margin-left: 70px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 300px;
  }
  .img-slider-home {
    width: 650px;
    margin-top: 50px;
    margin-left: 130px;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 33px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 23px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 33px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 23px;
  }
  .border-slider-about {
    width: 450px;
  }
  .border-slider-services {
    width: 455px;
  }
  .border-slider-ciber {
    width: 470px;
  }
  .border-slider-blog {
    width: 355px;
  }
  .border-slider-contact {
    width: 465px;
  }
}
@media (max-width: 1457px) {
  .img-slider-pages {
    margin-left: 100px;
  }
}
@media (max-width: 1382px) {
  .container-fluid-offer {
    height: 530px;
  }
  .img-fluid-offer {
    width: 400px;
    max-width: 100%;
    margin-left: 90px;
    margin-top: 30px;
  }
  .h-offer {
    width: 444px;
    font-weight: 600;
    font-size: 28px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 19px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 20px;
    margin-right: 70px;
    margin-top: -20px;
  }
  .h-titles {
    font-size: 50px;
  }
  .h-titles-ciber {
    font-size: 45px;
  }
  .h-titles-ciber-SGSI {
    font-size: 45px;
  }
  .border-2 {
    width: 31% !important;
    margin-top: -40px;
  }
  .border-3 {
    margin-left: 69%;
    margin-top: -20px;
  }
  .text-wrap-service {
    font-size: 18px;
    width: 310px;
    height: 100%;
    font-weight: 500;
    margin-top: 60px;
  }
  .p-services {
    padding-top: 280px;
    text-align: justify;
    height: 400px;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 50px;
    margin-left: 50px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 50px;
  }
  .col-img-consult {
    width: 450px;
    padding-right: 60px;
    padding-left: 60px;
  }
  .img-consult {
    width: 320px;
  }
  .p-consult {
    margin-top: 20px;
    margin-right: 0px;
    font-size: 23px;
    text-align: justify;
    font-weight: 400;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 18px;
    width: 230px;
    height: 35px;
    margin-top: 10px;
    padding-top: 5px;
  }
  .border-8 {
    width: 32%;
    margin-top: -40px;
  }
  .border-9 {
    margin-left: 68%;
    margin-top: -20px;
  }
  .container-fluid-company {
    height: 480px;
  }
  .row-company {
    padding-left: 60px;
    padding-right: 60px;
  }
  .img-company {
    width: 400px;
  }
  .p-about-us {
    margin-top: 0;
    margin-bottom: 60px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 22px;
    text-align: justify;
  }
  .border-4 {
    width: 32%;
    margin-top: -40px;
  }
  .border-5 {
    margin-left: 68%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 34px;
  }
  .container-fluid-values {
    width: 100%;
    height: 640px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 17px;
    text-align: left;
    width: 80%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 50px;
    margin-left: 120px;
    font-size: 29px;
  }
  .p-values {
    padding-top: 60px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 340px;
  }
  .col-value2 {
    height: 340px;
  }
  .col-value3 {
    height: 340px;
  }
  .container-fluid-mission-vision {
    height: 580px;
  }
  .row-m-v {
    margin-top: 20px;
    height: 450px;
  }
  .text-wrap-mission {
    margin-top: 0px;
    width: 550px;
    font-size: 18px;
  }
  .text-wrap-vision {
    margin-top: 0px;
    width: 550px;
    font-size: 18px;
  }
  .text-wrap-mission .values {
    font-size: 36px;
  }
  .text-wrap-vision .values {
    font-size: 36px;
  }
  .text-wrap-mission .p-m-v {
    width: 480px;
  }
  .text-wrap-vision .p-m-v {
    width: 480px;
  }
  .services-link-infra {
    font-size: 30px;
  }
  .services-link-innova {
    font-size: 30px;
  }
  .services-link-net {
    font-size: 30px;
  }
  .services-link-air {
    font-size: 30px;
    margin-left: -5px;
  }
  .col-icons {
    width: 225px;
    height: 230px;
    border-radius: 120px;
  }
  .img-services {
    width: 180px;
  }
  .container-fluid-infra {
    height: 600px;
  }
  .row-infraestructure {
    margin-right: 50px;
    margin-left: 150px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 100px;
    font-size: 21px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 50px;
  }
  .img-infra {
    width: 300px;
  }
  .border-14 {
    width: 35%;
    margin-top: -40px;
  }
  .border-15 {
    margin-left: 65%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 605px;
  }
  .row-innovation {
    margin-right: 50px;
    margin-left: 150px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 70px;
    padding-left: 40px;
    font-size: 21px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 60px;
  }
  .img-innova {
    width: 300px;
  }
  .border-10 {
    width: 26%;
    margin-top: -36px;
  }
  .border-11 {
    margin-left: 74%;
    margin-top: -20px;
  }
  .list-networks-services {
    padding-top: 50px;
    padding-left: 100px;
    padding-right: 50px;
    font-size: 21px;
  }
  .border-12 {
    width: 16%;
    margin-top: -38px;
  }
  .border-13 {
    margin-left: 84%;
    margin-top: -20px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 70px;
    padding-left: 40px;
    font-size: 21px;
  }
  .border-18 {
    width: 24%;
    margin-top: -36px;
  }
  .border-19 {
    margin-left: 76%;
    margin-top: -20px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 60px;
    margin-left: 60px;
    margin-bottom: 10px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 100px;
    padding-left: 40px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 20px;
  }
  .container-fluid-ciber-specialized {
    height: 1000px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 60px;
    margin-left: 60px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 50px;
    margin-left: 150px;
    font-size: 21px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 50px;
    margin-left: 160px;
    font-size: 21px;
  }
  .container-fluid-ciber-quality {
    height: 920px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 60px;
    margin-left: 60px;
  }
  .title-form {
    padding-top: 40px;
    font-size: 35px;
  }
  .title-form2 {
    padding-top: 10px;
    font-size: 35px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 29px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 250px;
  }
  #contact-titles {
    font-size: 22px;
  }
  .img-slider-home {
    width: 600px;
    margin-top: 50px;
    margin-left: 100px;
  }
  .container-diagnostic-form {
    margin-left: 140px;
    width: 1100px;
  }
  .soon1 {
    font-size: 28px;
    padding-top: 40px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .btn-diagnostic-form {
    font-size: 22px;
    width: 280px;
    height: 45px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 820px;
  }
  .h-titles-policy {
    font-size: 45px;
    margin-top: 80px;
  }
  .title-policy {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .p-content-policy {
    font-size: 19px;
  }
  .container-politicy {
    margin: 120px;
    margin-top: 50px;
  }
}
@media (max-width: 1339px) {
  .img-slider-pages {
    width: 580px;
    margin-top: 80px;
    margin-left: 100px;
  }
}
@media (max-width: 1308px) {
  .border-slider-home {
    width: 200px;
  }
  .container-diagnostic-form {
    margin-left: 120px;
    width: 1030px;
  }
  .soon1 {
    font-size: 27px;
  }
  .btn-diagnostic-form {
    font-size: 22px;
    width: 280px;
    height: 45px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 750px;
  }
}
@media (max-width: 1300px) {
  .img-slider-home {
    width: 600px;
    margin-top: 50px;
    margin-left: 80px;
  }
}
@media (max-width: 1274px) {
  .border-slider-ciber {
    width: 440px;
  }
}
@media (max-width: 1270px) {
  .border-slider-contact {
    width: 305px;
  }
}
@media (max-width: 1266px) {
  .col-offer {
    margin-left: 30px;
    margin-right: 30px;
  }
  .img-company {
    width: 400px;
    margin-left: 50px;
  }
  .col-company {
    margin-top: 10px;
  }
  .col-icons {
    width: 215px;
    height: 220px;
    border-radius: 120px;
  }
  .img-services {
    width: 170px;
  }
  .container-fluid-infra {
    height: 580px;
  }
  .row-infraestructure {
    margin-right: 40px;
    margin-left: 130px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 20px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 40px;
  }
  .img-infra {
    width: 280px;
  }
  .border-16 {
    width: 38%;
    margin-top: -36px;
  }
  .border-17 {
    margin-left: 62%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 605px;
  }
  .row-innovation {
    margin-right: 40px;
    margin-left: 130px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 60px;
    padding-left: 40px;
    font-size: 20px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 60px;
  }
  .img-innova {
    width: 280px;
  }
  .border-10 {
    width: 24%;
    margin-top: -36px;
  }
  .border-11 {
    margin-left: 76%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 560px;
  }
  .list-networks-services {
    padding-top: 50px;
    padding-right: 40px;
    font-size: 20px;
  }
  .border-12 {
    width: 14%;
    margin-top: -38px;
  }
  .border-13 {
    margin-left: 86%;
    margin-top: -20px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 60px;
    padding-left: 40px;
    font-size: 20px;
  }
  .border-18 {
    width: 22%;
    margin-top: -36px;
  }
  .border-19 {
    margin-left: 78%;
    margin-top: -20px;
  }
  .img-ciber {
    width: 330px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 20px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 10px;
  }
  .container-fluid-ciber-specialized {
    height: 950px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
    margin-left: 50px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 120px;
    font-size: 20px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 140px;
    font-size: 20px;
  }
  .container-fluid-ciber-quality {
    height: 850px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
    margin-left: 50px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 40px;
    margin-left: 40px;
    font-size: 26px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 60px;
    padding-right: 80px;
  }
  .title-form {
    padding-top: 40px;
    font-size: 33px;
  }
  .title-form2 {
    padding-top: 10px;
    font-size: 33px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 27px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 200px;
  }
  #contact-titles {
    font-size: 22px;
  }
  .img-slider-home {
    width: 550px;
    margin-top: 80px;
    margin-left: 80px;
  }
  .img-slider-pages {
    width: 550px;
    margin-top: 80px;
    margin-left: 80px;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 30px;
    margin-left: 150px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 30px;
    margin-left: 150px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 30px;
    margin-left: 80px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 22px;
    margin-left: 150px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 22px;
    margin-left: 80px;
  }
  .border-slider-home {
    margin-left: 150px;
  }
  .btn-slider {
    position: absolute;
    top: 500px;
    left: 150px;
  }
  .border-slider-about {
    margin-left: 80px;
    width: 405px;
  }
  .border-slider-services {
    width: 410px;
  }
  .border-slider-blog {
    width: 325px;
  }
  .border-slider-contact {
    width: 425px;
  }
}
@media (max-width: 1220px) {
  .container-diagnostic-form {
    margin-left: 90px;
    width: 1015px;
  }
  .soon1 {
    font-size: 26px;
  }
  .btn-diagnostic-form {
    font-size: 22px;
    width: 280px;
    height: 45px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 735px;
  }
}
@media (max-width: 1190px) {
  .col-offer {
    margin-left: 0;
    margin-right: 0;
  }
  .img-fluid-offer {
    width: 380px;
    margin-left: 50px;
  }
  .h-titles {
    font-size: 48px;
  }
  .container-fluid-company {
    height: 450px;
  }
  .img-company {
    width: 400px;
    margin-left: 30px;
  }
  .col-company {
    margin-left: 50px;
  }
  .p-about-us {
    margin-right: 0;
    margin-left: 10px;
    font-size: 20px;
  }
  .h-titles-ciber {
    font-size: 43px;
  }
  .h-titles-ciber-SGSI {
    font-size: 43px;
  }
  .border-2 {
    width: 29% !important;
    margin-top: -40px;
  }
  .border-3 {
    margin-left: 71%;
    margin-top: -20px;
  }
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 70px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 286px;
    height: 100%;
    font-weight: 500;
    margin-top: 50px;
  }
  .p-services {
    padding-top: 280px;
    text-align: justify;
    height: 390px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 195px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 30px;
  }
  .col-img-consult {
    width: 400px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .img-consult {
    width: 300px;
  }
  .p-consult {
    margin-top: 20px;
    margin-right: 0px;
    font-size: 22px;
    text-align: justify;
    font-weight: 400;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 17px;
    width: 210px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
  }
  .border-4 {
    width: 30%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 70%;
    margin-top: -20px;
  }
  .border-6 {
    width: 41%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 59%;
    margin-top: -20px;
  }
  .container-fluid-values {
    width: 100%;
    height: 630px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 16px;
    text-align: left;
    width: 85%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 40px;
    margin-left: 120px;
    font-size: 28px;
  }
  .p-values {
    padding-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 310px;
  }
  .col-value2 {
    height: 310px;
  }
  .col-value3 {
    height: 310px;
  }
  .container-fluid-mission-vision {
    height: 560px;
  }
  .row-m-v {
    margin-top: 0;
  }
  .text-wrap-mission {
    width: 500px;
    font-size: 17px;
  }
  .text-wrap-vision {
    width: 500px;
    font-size: 17px;
  }
  .text-wrap-mission .values {
    font-size: 34px;
  }
  .text-wrap-vision .values {
    font-size: 34px;
  }
  .text-wrap-mission .p-m-v {
    width: 450px;
  }
  .text-wrap-vision .p-m-v {
    width: 450px;
  }
  .container-fluid-services-sp {
    width: 100%;
    height: 550px;
    margin-top: 30px;
  }
  .row-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .row-services-portfolio {
    margin-top: 60px;
  }
  .services-link-infra {
    font-size: 28px;
  }
  .services-link-innova {
    font-size: 28px;
  }
  .services-link-net {
    font-size: 28px;
    margin-left: 20px;
  }
  .services-link-air {
    font-size: 28px;
    margin-left: -15px;
  }
  .col-icons {
    width: 195px;
    height: 200px;
    border-radius: 120px;
  }
  .img-services {
    width: 140px;
  }
  .container-fluid-infra {
    height: 560px;
  }
  .row-infraestructure {
    margin-right: 30px;
    margin-left: 130px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 20px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 30px;
  }
  .img-infra {
    width: 250px;
  }
  .border-14 {
    width: 34%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 66%;
    margin-top: -20px;
  }
  .border-16 {
    width: 37%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 63%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 570px;
  }
  .row-innovation {
    margin-right: 30px;
    margin-left: 130px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 50px;
    padding-left: 30px;
    font-size: 20px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 40px;
    padding-right: 50px;
  }
  .img-innova {
    width: 250px;
  }
  .border-10 {
    width: 23%;
    margin-top: -36px;
  }
  .border-11 {
    margin-left: 77%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 540px;
  }
  .border-12 {
    width: 12%;
    margin-top: -38px;
  }
  .border-13 {
    margin-left: 88%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 570px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 50px;
    padding-left: 30px;
    font-size: 20px;
  }
  .border-18 {
    width: 21%;
    margin-top: -36px;
  }
  .border-19 {
    margin-left: 79%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 820px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 50px;
    margin-left: 50px;
    margin-bottom: 10px;
  }
  .img-ciber {
    width: 300px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 20px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 19px;
  }
  .container-fluid-ciber-specialized {
    height: 920px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
    margin-left: 50px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 100px;
    font-size: 19px;
  }
  .container-fluid-ciber-quality {
    height: 880px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 140px;
    font-size: 20px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
    margin-left: 50px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 25px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 60px;
    padding-right: 80px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 150px;
  }
  .slider1 {
    height: 600px;
  }
  .slider2 {
    height: 600px;
  }
  .slider3 {
    height: 600px;
  }
  .img-slider-home {
    width: 500px;
    margin-top: 80px;
    margin-left: 80px;
  }
  .img-slider-pages {
    width: 480px;
    margin-top: 100px;
    margin-left: 70px;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 30px;
    margin-left: 150px;
    margin-top: 200px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 30px;
    margin-left: 150px;
    margin-top: 160px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 30px;
    margin-left: 80px;
    margin-top: 200px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 22px;
    margin-left: 150px;
  }
  .border-slider-home {
    margin-left: 150px;
  }
  .btn-slider {
    position: absolute;
    top: 450px;
    left: 150px;
  }
  .container-slider-about-us {
    height: 600px;
  }
  .container-slider-services {
    height: 600px;
  }
  .container-slider-cybersecurity {
    height: 600px;
  }
  .container-slider-blog {
    height: 600px;
  }
  .container-slider-contact {
    height: 600px;
  }
  .container-fluid-services-form {
    height: 500px;
  }
  .container-diagnostic-form {
    margin-left: 90px;
    width: 1015px;
  }
  .soon1 {
    font-size: 25px;
  }
  .btn-diagnostic-form {
    font-size: 21px;
    width: 280px;
    height: 45px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 735px;
  }
  .h-titles-policy {
    font-size: 42px;
  }
  .title-policy {
    font-size: 30px;
    font-weight: 500;
  }
  .p-content-policy {
    font-size: 18px;
  }
  .container-politicy {
    margin: 100px;
    margin-top: 50px;
  }
  .table-policy {
    width: 700px;
    margin-bottom: 50px;
  }
  .title-column {
    font-size: 16px;
  }
  .content-column {
    font-size: 14px;
  }
}
@media (max-width: 1163px) {
  .border-slider-ciber {
    width: 400px;
  }
}
@media (max-width: 1157px) {
  .title-form {
    padding-top: 20px;
    font-size: 33px;
  }
  .container-diagnostic-form {
    margin-left: 125px;
    width: 900px;
    height: 170px;
  }
  .soon1 {
    font-size: 23px;
  }
  .btn-diagnostic-form {
    font-size: 20px;
    width: 230px;
    height: 40px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 675px;
  }
}
@media (max-width: 1155px) {
  .border-slider-contact {
    width: 280px;
  }
}
@media (max-width: 1133px) {
  .border-slider-services {
    width: 370px;
  }
}
@media (max-width: 1122px) {
  .border-slider-about {
    width: 360px;
  }
}
@media (max-width: 1110px) {
  .border-slider-ciber {
    width: 220px;
  }
}
@media (max-width: 1105px) {
  .img-fluid-offer {
    width: 350px;
    max-width: 100%;
    margin-left: 50px;
    margin-top: 30px;
  }
  .h-offer {
    width: 411px;
    font-weight: 600;
    font-size: 26px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 19px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 20px;
    margin-right: 70px;
    margin-top: -20px;
  }
  .btn-offer {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 17px;
    width: 200px;
    height: 35px;
    margin-top: 30px;
    padding-top: 10px;
  }
  .h-titles {
    font-size: 45px;
  }
  .h-titles-ciber {
    font-size: 40px;
  }
  .h-titles-ciber-SGSI {
    font-size: 40px;
  }
  .border-2 {
    width: 28% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 71%;
    margin-top: -20px;
  }
  .row-company {
    padding-left: 40px;
    padding-right: 40px;
  }
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 60px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 265px;
    height: 100%;
    font-weight: 500;
    margin-top: 35px;
  }
  .p-services {
    padding-top: 275px;
    text-align: justify;
    height: 380px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 175px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 17px;
    width: 210px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
  }
  .border-8 {
    width: 30%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 70%;
    margin-top: -20px;
  }
  .border-6 {
    width: 40%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 60%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 33px;
  }
  .container-fluid-values {
    width: 100%;
    height: 580px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 15px;
    text-align: left;
    width: 90%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 40px;
    margin-left: 120px;
    font-size: 26px;
  }
  .p-values {
    padding-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 280px;
  }
  .col-value2 {
    height: 280px;
  }
  .col-value3 {
    height: 280px;
  }
  .container-fluid-mission-vision {
    height: 500px;
  }
  .row-m-v {
    margin-top: -30px;
    height: 440px;
  }
  .text-wrap-mission {
    width: 450px;
    font-size: 16px;
  }
  .text-wrap-vision {
    width: 450px;
    font-size: 16px;
  }
  .text-wrap-mission .values {
    font-size: 32px;
  }
  .text-wrap-vision .values {
    font-size: 32px;
  }
  .text-wrap-mission .p-m-v {
    width: 400px;
  }
  .text-wrap-vision .p-m-v {
    width: 400px;
  }
  .row-container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .row-services-portfolio {
    margin-top: 50px;
  }
  .services-link {
    display: block;
    color: #000;
    text-decoration: none;
    font-size: 23px;
    font-weight: 500;
  }
  .img-services {
    width: 145px;
  }
  .container-fluid-infra {
    height: 530px;
  }
  .row-infraestructure {
    margin-right: 30px;
    margin-left: 100px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 19px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 30px;
  }
  .img-infra {
    width: 250px;
  }
  .container-fluid-innova {
    height: 550px;
  }
  .row-innovation {
    margin-right: 30px;
    margin-left: 100px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 40px;
    padding-left: 20px;
    font-size: 19px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 40px;
    padding-right: 40px;
  }
  .img-innova {
    width: 250px;
  }
  .border-10 {
    width: 23%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 77%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 520px;
  }
  .list-networks-services {
    padding-top: 40px;
    padding-right: 40px;
    font-size: 19px;
  }
  .border-12 {
    width: 12%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 88%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 550px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 40px;
    padding-left: 20px;
    font-size: 19px;
  }
  .border-18 {
    width: 20%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 80%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 780px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 10px;
  }
  .img-ciber {
    width: 300px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 70px;
    padding-left: 10px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 18px;
  }
  .container-fluid-ciber-specialized {
    height: 880px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 80px;
    font-size: 18px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 100px;
    font-size: 18px;
  }
  .container-fluid-ciber-quality {
    height: 800px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .row-attention {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-left: 100px;
  }
  .slider1 {
    height: 550px;
  }
  .slider2 {
    height: 550px;
  }
  .slider3 {
    height: 550px;
  }
  .img-slider-home {
    width: 450px;
    margin-top: 80px;
    margin-left: 80px;
  }
  .img-slider-pages {
    width: 450px;
    margin-top: 100px;
    margin-left: 60px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    height: 50px;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 28px;
    margin-left: 120px;
    margin-top: 150px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 28px;
    margin-left: 120px;
    margin-top: 110px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 28px;
    margin-left: 80px;
    margin-top: 200px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 20px;
    margin-left: 120px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 20px;
    margin-left: 80px;
  }
  .border-slider-home {
    margin-left: 120px;
    width: 235px;
  }
  .btn-slider {
    position: absolute;
    top: 400px;
    left: 120px;
  }
  .container-slider-about-us {
    height: 550px;
  }
  .container-slider-services {
    height: 550px;
  }
  .container-slider-cybersecurity {
    height: 550px;
  }
  .container-slider-blog {
    height: 550px;
  }
  .container-slider-contact {
    height: 550px;
  }
  .border-slider-about {
    margin-left: 80px;
    width: 375px;
  }
  .border-slider-services {
    width: 385px;
  }
  .border-slider-ciber {
    width: 400px;
  }
  .border-slider-blog {
    width: 300px;
  }
  .border-slider-contact {
    width: 395px;
  }
  .h-titles-policy {
    font-size: 38px;
    margin-top: 50px;
  }
  .title-policy {
    font-size: 28px;
  }
  .p-content-policy {
    font-size: 18px;
  }
  .container-politicy {
    margin: 70px;
    margin-top: 40px;
  }
}
@media (max-width: 1102px) {
  .border-slider-ciber {
    width: 375px;
  }
  .social-networks {
    font-size: 16px;
  }
}
@media (max-width: 1095px) {
  .border-slider-contact {
    width: 260px;
  }
  .container-diagnostic-form {
    margin-left: 110px;
    width: 855px;
    height: 170px;
  }
  .soon1 {
    font-size: 22px;
  }
  .btn-diagnostic-form {
    font-size: 20px;
    width: 230px;
    height: 40px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 625px;
  }
}
@media (max-width: 1074px) {
  .border-slider-services {
    width: 340px;
  }
}
@media (max-width: 1064px) {
  .border-slider-about {
    width: 340px;
  }
}
@media (max-width: 1053px) {
  .border-slider-ciber {
    width: 205px;
  }
}
@media (max-width: 1040px) {
  .container-lines {
    margin-left: 180px;
  }
}
@media (max-width: 1020px) {
  .header {
    position: relative;
  }
  .navbar-menu {
    display: none;
  }
  .navbar-menu2 {
    display: flex;
    flex-direction: column;
    background-color: #0081c6e8;
    position: absolute;
    top: 100px;
    width: 300px;
    z-index: 1;
    height: 360px;
    overflow-y: auto;
    right: 100%;
    border-radius: 0 0 0 30px;
    padding-left: 0;
    margin-top: 10px;
  }
  .nav-toggle {
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 90%;
    bottom: 30%;
    align-items: flex-end;
    margin-top: 4px;
  }
  .nav-toggle:hover {
    background-color: #ffffff8a;
    color: #fff;
    border-radius: 20px;
  }
  .navbar-menu_visible {
    right: 0;
  }
  .nav-item {
    text-align: right;
  }
  .btn-outline-success-2 {
    color: white;
    background-color: #0082c6;
    margin-right: 10px;
    margin-top: 20px;
    width: auto;
    height: 35px;
    font-weight: 500;
    font-size: 20px;
  }
  .btn-outline-success-2:hover {
    color: #0082c6;
    background-color: white;
    border-color: #0082c6;
  }
}
@media (max-width: 1018px) {
  .container-fluid-services-sp {
    height: 500px;
  }
  .row-services-portfolio {
    padding-left: 30px;
    padding-right: 30px;
  }
  .row-container {
    margin-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .services-link-infra {
    font-size: 26px;
    margin-left: -20px;
  }
  .services-link-innova {
    font-size: 26px;
    margin-left: 10px;
  }
  .services-link-net {
    font-size: 26px;
  }
  .services-link-air {
    font-size: 26px;
  }
  .col-icons {
    width: 175px;
    height: 180px;
    border-radius: 120px;
  }
  .img-services {
    width: 135px;
  }
  .container-fluid-infra {
    height: 520px;
  }
  .row-infraestructure {
    margin-right: 30px;
    margin-left: 80px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 18px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 20px;
  }
  .img-infra {
    width: 230px;
  }
  .border-14 {
    width: 33%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 67%;
    margin-top: -20px;
  }
  .border-16 {
    width: 36%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 64%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 530px;
  }
  .row-innovation {
    margin-right: 30px;
    margin-left: 80px;
  }
  .list-innovation-services {
    padding-top: 60px;
    padding-right: 40px;
    padding-left: 20px;
    font-size: 18px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 40px;
    padding-right: 30px;
  }
  .img-innova {
    width: 230px;
  }
  .border-10 {
    width: 21%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 79%;
    margin-top: -20px;
  }
  .list-networks-services {
    padding-top: 40px;
    padding-right: 40px;
    font-size: 18px;
  }
  .border-12 {
    width: 10%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 90%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 530px;
  }
  .list-air-services {
    padding-top: 100px;
    padding-right: 40px;
    padding-left: 20px;
    font-size: 18px;
  }
  .border-18 {
    width: 19%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 81%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 750px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 10px;
  }
  .img-ciber {
    width: 280px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 60px;
    padding-left: 0;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 17px;
  }
  .container-fluid-ciber-specialized {
    height: 860px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 60px;
    font-size: 17px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 60px;
    font-size: 17px;
  }
  .container-fluid-ciber-quality {
    height: 750px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 24px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
    margin-left: 0;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 60px;
    padding-right: 70px;
  }
  .title-form {
    padding-top: 20px;
    font-size: 31px;
  }
  .title-form2 {
    padding-top: 10px;
    font-size: 31px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 25px;
  }
  .container-lines {
    margin-left: 150px;
  }
  #contact-titles {
    font-size: 21px;
  }
  .container-diagnostic-form {
    margin-left: 100px;
    width: 800px;
    height: 170px;
  }
  .soon1 {
    font-size: 21px;
  }
  .btn-diagnostic-form {
    font-size: 20px;
    width: 230px;
    height: 40px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 573px;
  }
  .h-titles-policy {
    font-size: 40px;
  }
  .container-politicy {
    margin: 70px;
    margin-top: 40px;
  }
  .table-policy {
    width: 650px;
  }
}
@media (max-width: 1014px) {
  .img-slider-pages {
    width: 420px;
  }
  .social-networks {
    font-size: 15px;
  }
}
@media (max-width: 978px) {
  .border-slider-about {
    width: 270px;
  }
}
@media (max-width: 988px) {
  .border-slider-services {
    width: 315px;
  }
}
@media (max-width: 990px) {
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 255px;
    height: 100%;
    font-weight: 500;
    margin-top: 25px;
  }
  .p-services {
    padding-top: 275px;
    text-align: justify;
    height: 370px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 160px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 15px;
    margin-left: 15px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 15px;
  }
  .col-img-consult {
    width: 300px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .img-consult {
    width: 250px;
  }
  .p-consult {
    margin-top: 10px;
    margin-right: 0px;
    font-size: 21px;
    text-align: justify;
    font-weight: 400;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 17px;
    width: 210px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
  }
  .row-about-us {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 80px;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-left: 40px;
  }
  .img-company {
    width: 340px;
  }
  .p-about-us {
    margin-top: 0;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 20px;
    text-align: justify;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 32px;
  }
  .container-fluid-values {
    width: 100%;
    height: 530px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 14px;
    text-align: left;
    width: 90%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: 100px;
    font-size: 25px;
  }
  .p-values {
    padding-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 250px;
  }
  .col-value2 {
    height: 250px;
  }
  .col-value3 {
    height: 250px;
  }
  .row-m-v {
    margin-top: -30px;
    height: 450px;
  }
  .text-wrap-mission {
    background-image: url("../public/images/about-us/mision-990.png");
    width: 400px;
    font-size: 15px;
  }
  .text-wrap-vision {
    background-image: url("../public/images/about-us/vision-990.png");
    width: 400px;
    font-size: 15px;
  }
  .text-wrap-mission .values {
    font-size: 30px;
  }
  .text-wrap-vision .values {
    font-size: 30px;
  }
  .text-wrap-mission .p-m-v {
    width: 350px;
    margin-left: 20px;
  }
  .text-wrap-vision .p-m-v {
    width: 350px;
    margin-left: 20px;
  }
  .row-container {
    padding-right: 10px;
    padding-left: 10px;
  }
  .services-link {
    display: block;
    color: #000;
    text-decoration: none;
    font-size: 22px;
    font-weight: 500;
  }
  .img-services {
    width: 125px;
  }
  .border-14 {
    width: 32%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 68%;
    margin-top: -20px;
  }
  .border-12 {
    width: 9%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 91%;
    margin-top: -20px;
  }
  .border-18 {
    width: 18%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 82%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 750px;
  }
  .container-lines {
    margin-left: 120px;
  }
}
@media (max-width: 970px) {
  .container-fluid-offer {
    height: 500px;
  }
  .img-fluid-offer {
    width: 330px;
    max-width: 100%;
    margin-left: 50px;
    margin-top: 30px;
  }
  .h-offer {
    width: 380px;
    font-weight: 600;
    font-size: 24px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 18px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 10px;
    margin-right: 20px;
    margin-top: -40px;
  }
  .h-titles {
    font-size: 43px;
  }
  .h-titles-ciber {
    font-size: 38px;
  }
  .h-titles-ciber-SGSI {
    font-size: 38px;
  }
  .border-2 {
    width: 26% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 73%;
    margin-top: -20px;
  }
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 18px;
    width: 325px;
    height: 100%;
    font-weight: 500;
    margin-top: 75px;
  }
  .p-services {
    padding-top: 280px;
    text-align: justify;
    height: 390px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 230px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .border-8 {
    width: 28%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 17px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .img-company {
    width: 320px;
  }
  .p-about-us {
    margin-top: 0;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 0;
    font-size: 19px;
  }
  .container-fluid-values {
    width: 100%;
    height: 520px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 13px;
    text-align: left;
    width: 90%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: 100px;
    font-size: 24px;
  }
  .p-values {
    padding-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 230px;
  }
  .col-value2 {
    height: 230px;
  }
  .col-value3 {
    height: 230px;
  }
  .container-fluid-mission-vision {
    height: 500px;
  }
  .border-16 {
    width: 35%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 65%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 520px;
  }
  .list-innovation-services {
    padding-top: 40px;
    padding-right: 40px;
    padding-left: 10px;
    font-size: 18px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 20px;
  }
  .img-innova {
    width: 230px;
  }
  .border-10 {
    width: 20%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 80%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 500px;
  }
  .list-networks-services {
    padding-top: 30px;
    padding-right: 20px;
    font-size: 18px;
  }
  .container-fluid-air {
    height: 520px;
  }
  .list-air-services {
    padding-top: 80px;
    padding-right: 40px;
    padding-left: 10px;
    font-size: 18px;
  }
  .container-fluid-ciber-SGSI {
    height: 730px;
  }
  .row-ciber {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 0;
  }
  .img-ciber {
    width: 260px;
  }
  .col-img-ciber {
    padding-top: 80px;
    padding-right: 50px;
    padding-left: 0;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 17px;
  }
  .container-fluid-ciber-specialized {
    height: 840px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 60px;
    font-size: 17px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 60px;
    font-size: 17px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 23px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
    margin-left: 0;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 50px;
  }
  .title-form {
    padding-top: 20px;
    font-size: 29px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 25px;
  }
  .container-lines {
    margin-left: 100px;
  }
  .row-attention {
    margin-left: 80px;
  }
  .slider1 {
    height: 500px;
  }
  .slider2 {
    height: 500px;
  }
  .slider3 {
    height: 500px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    height: 10px;
  }
  .img-slider-home {
    width: 400px;
    margin-top: 80px;
    margin-left: 150px;
  }
  .img-slider-pages {
    width: 400px;
    margin-top: 80px;
    margin-left: 60px;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 28px;
    margin-left: 100px;
    margin-top: 130px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 28px;
    margin-left: 100px;
    margin-top: 90px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 28px;
    margin-left: 80px;
    margin-top: 150px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 20px;
    margin-left: 100px;
  }
  .border-slider-home {
    margin-left: 100px;
    width: 190px;
  }
  .btn-slider {
    font-size: 17px;
    width: 180px;
    height: 38px;
    padding-top: 7px;
    position: absolute;
    top: 380px;
    left: 100px;
  }
  .wave-slider {
    display: none;
  }
  .wave-slider2 {
    display: block;
    position: relative;
  }
  .container-slider-about-us {
    height: 500px;
  }
  .container-slider-services {
    height: 500px;
  }
  .container-slider-cybersecurity {
    height: 500px;
  }
  .container-slider-blog {
    height: 500px;
  }
  .container-slider-contact {
    height: 500px;
  }
  .container-fluid-services-form {
    height: 470px;
  }
  .container-diagnostic-form {
    margin-left: 100px;
    width: 755px;
    height: 150px;
  }
  .soon1 {
    font-size: 20px;
    padding-top: 30px;
  }
  .btn-diagnostic-form {
    font-size: 18px;
    width: 200px;
    height: 40px;
    margin-top: 70px;
    padding-top: 10px;
    margin-left: 555px;
  }
  .social-networks {
    display: none;
  }
  .social-networks-hidden {
    display: block;
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
  }
  .container-politics {
    display: block;
    height: 45px;
  }
  .icons-social-networks {
    width: 50px;
    justify-content: center;
  }
  .icons-social-networks2 {
    width: 50px;
    display: flex;
  }
  .container-footer {
    width: 100%;
    height: 110px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #09294f;
  }
  .content-foo-social {
    display: flex;
    flex-direction: row;
    text-align: start;
  }
  .content-foo2 {
    display: none;
  }
  .wave2 {
    display: none;
  }
  .footer-copyright {
    border-top: 4px solid #09294f;
    text-align: center;
    margin: 0;
    color: #09294f;
  }
  .h-titles-policy {
    font-size: 38px;
  }
  .title-policy {
    font-size: 28px;
  }
  .p-content-policy {
    font-size: 17px;
  }
  .container-politicy {
    margin: 30px;
    margin-top: 30px;
  }
}
@media (max-width: 930px) {
  .container-fluid-company {
    height: 400px;
  }
  .row-company {
    margin-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .img-company {
    margin-left: 0;
  }
  .p-about-us {
    font-size: 18px;
  }
  .row-m-v {
    margin-top: 0;
  }
  .text-wrap-mission {
    background-image: url("../public/images/about-us/mision-930.png");
    width: 350px;
    font-size: 14px;
    height: 480px;
    margin-top: -50px;
  }
  .text-wrap-vision {
    background-image: url("../public/images/about-us/vision-930.png");
    width: 350px;
    font-size: 14px;
    height: 480px;
    margin-top: -50px;
  }
  .text-wrap-mission .values {
    font-size: 28px;
    margin-top: 190px;
  }
  .text-wrap-vision .values {
    font-size: 28px;
    margin-top: 190px;
  }
  .text-wrap-mission .p-m-v {
    width: 300px;
    margin-left: 20px;
  }
  .text-wrap-vision .p-m-v {
    width: 300px;
    margin-left: 20px;
  }
  .slider1 {
    height: 400px;
  }
  .slider2 {
    height: 400px;
  }
  .slider3 {
    height: 400px;
  }
  .col-slider-home {
    display: none;
  }
  .col-slider-pages {
    display: none;
  }
  .img-slider-home {
    display: none;
  }
  .img-slider-pages {
    display: none;
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin-top: -60px;
  }
  .text-wrap-slider {
    width: 95%;
  }
  .text-wrap-slider-pages {
    width: 95%;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 32px;
    margin-left: 130px;
    margin-top: 100px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 32px;
    margin-left: 130px;
    margin-top: 60px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 22px;
    margin-left: 130px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 22px;
    margin-left: 50px;
    margin-right: 30px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 28px;
    margin-left: 50px;
    margin-top: 130px;
  }
  .border-slider-home {
    margin-left: 130px;
    width: 365px;
  }
  .btn-slider {
    position: absolute;
    top: 300px;
    left: 130px;
  }
  .container-slider-about-us {
    height: 400px;
  }
  .container-slider-services {
    height: 400px;
  }
  .container-slider-cybersecurity {
    height: 400px;
  }
  .container-slider-blog {
    height: 400px;
  }
  .container-slider-contact {
    height: 400px;
  }
  .border-slider-about {
    margin-left: 50px;
    width: 540px;
  }
  .border-slider-services {
    margin-left: 50px;
    width: 510px;
  }
  .border-slider-ciber {
    margin-left: 50px;
    width: 605px;
  }
  .border-slider-blog {
    margin-left: 50px;
    width: 300px;
  }
  .border-slider-contact {
    margin-left: 50px;
    width: 395px;
  }
}
@media (max-width: 926px) {
  .img-fluid-offer {
    width: 320px;
    max-width: 100%;
    margin-left: 40px;
    margin-top: 30px;
  }
  .list-innovation-services {
    padding-top: 30px;
    padding-right: 40px;
    padding-left: 10px;
    font-size: 18px;
    list-style-type: "✓ ";
  }
  .border-10 {
    width: 19%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 81%;
    margin-top: -20px;
  }
  .border-12 {
    width: 7%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 93%;
    margin-top: -20px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 40px;
    padding-left: 10px;
    font-size: 18px;
  }
  .border-18 {
    width: 17%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 83%;
    margin-top: -20px;
  }
  .p-ciber {
    font-size: 16px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 40px;
    font-size: 17px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 40px;
    font-size: 17px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    margin-left: 40px;
  }
  .container-lines {
    margin-left: 100px;
  }
  .row-attention {
    margin-left: 80px;
  }
  .img-map {
    width: 160px;
  }
  .h-titles-attention1 {
    font-size: 33px;
  }
  .h-titles-attention2 {
    font-size: 43px;
  }
  .h-titles-attention3 {
    font-size: 33px;
  }
  .h-titles-attention4 {
    font-size: 43px;
  }
  .icon {
    width: 65px;
    height: 65px;
  }
  .wpp-contact {
    font-size: 30px;
  }
  .mail-contact {
    font-size: 30px;
  }
}
@media (max-width: 900px) {
  .container-fluid-services-sp {
    height: 880px;
  }
  .row-services-portfolio {
    display: none;
  }
  .row-services-portfolio2 {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    width: 100%;
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-evenly;
  }
  .row-services-portfolio3 {
    display: flex;
    flex-direction: row;
    margin-top: 60px;
    width: 100%;
    max-width: 100%;
    padding-right: 30px;
    padding-left: 30px;
    justify-content: space-evenly;
  }
  .service-networks {
    margin-left: 30px;
  }
  .service-air {
    margin-left: 60px;
  }
  .col-icons {
    width: 195px;
    height: 200px;
    border-radius: 120px;
  }
  .service-item {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: center;
    margin-top: 10px;
  }
  .img-services {
    width: 150px;
  }
  .services-link-infra {
    font-size: 30px;
  }
  .services-link-innova {
    font-size: 30px;
  }
  .services-link-net {
    font-size: 30px;
  }
  .services-link-air {
    font-size: 30px;
    margin-left: -25px;
  }
  .container-diagnostic-form {
    margin-left: 70px;
  }
}
@media (max-width: 879px) {
  .container-fluid-ciber-specialized {
    height: 890px;
  }
}
@media (max-width: 870px) {
  .img-fluid-offer {
    width: 320px;
    margin-left: 30px;
  }
  h4 {
    font-size: 20px;
  }
  .container-politics {
    height: 40px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .icons-social-networks {
    width: 40px;
  }
  .icons-social-networks2 {
    width: 40px;
  }
  .container-fluid-company {
    height: 400px;
  }
  .row-company {
    margin-top: 70px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .img-company {
    margin-left: 0;
    width: 280px;
  }
  .p-about-us {
    font-size: 18px;
  }
  .border-4 {
    width: 28%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 32px;
  }
  .container-fluid-values {
    width: 100%;
    height: 500px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 13px;
    text-align: left;
    width: 95%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: 100px;
    font-size: 23px;
  }
  .p-values {
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 220px;
  }
  .col-value2 {
    height: 220px;
  }
  .col-value3 {
    height: 220px;
  }
  .container-fluid-infra {
    height: 520px;
  }
  .row-infraestructure {
    margin-right: 30px;
    margin-left: 60px;
  }
  .list-infraestructure-services {
    padding-top: 30px;
    padding-left: 80px;
    font-size: 17px;
    list-style-type: "✓ ";
  }
  .col-img-infra {
    padding-top: 30px;
    padding-left: 10px;
  }
  .img-infra {
    width: 220px;
  }
  .border-14 {
    width: 31%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 69%;
    margin-top: -20px;
  }
  .border-16 {
    width: 34%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 66%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 500px;
  }
  .row-innovation {
    margin-right: 30px;
    margin-left: 60px;
  }
  .list-innovation-services {
    padding-top: 30px;
    padding-right: 30px;
    padding-left: 10px;
    font-size: 17px;
    list-style-type: "✓ ";
  }
  .col-img-innova {
    padding-top: 20px;
    padding-right: 10px;
  }
  .img-innova {
    width: 220px;
  }
  .border-10 {
    width: 18%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 82%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 480px;
  }
  .list-networks-services {
    padding-top: 30px;
    padding-right: 20px;
    font-size: 17px;
  }
  .border-12 {
    width: 5%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 95%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 500px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 10px;
    font-size: 17px;
  }
  .border-18 {
    width: 15%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 85%;
    margin-top: -20px;
  }
  .img-ciber {
    width: 270px;
  }
  .container-fluid-ciber-SGSI {
    height: 810px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 30px;
    margin-left: 30px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 20px;
    font-size: 17px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 20px;
    font-size: 17px;
  }
  .container-fluid-ciber-quality {
    height: 780px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 30px;
    margin-left: 30px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 23px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
    margin-left: 0;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 80px;
    padding-right: 20px;
    padding-left: 30px;
  }
  .title-form {
    padding-top: 20px;
    font-size: 27px;
  }
  .title-form2 {
    padding-top: 13px;
    font-size: 30px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 24px;
  }
  .container-lines {
    margin-left: 80px;
  }
  .row-attention {
    margin-left: 70px;
  }
  #contact-titles {
    font-size: 20px;
  }
  input,
  textarea {
    font-size: 15px;
    width: 95%;
  }
  #input-submit {
    font-size: 17px;
    width: 671px;
  }
  textarea {
    height: 163px;
  }
  .container-diagnostic-form {
    margin-left: 50px;
  }
}
@media (max-width: 840px) {
  .text-wrap-slider .title-slider-home3 {
    width: 70%;
  }
}
@media (max-width: 830px) {
  .img-fluid-offer {
    width: 300px;
    max-width: 100%;
    margin-left: 20px;
    margin-top: 50px;
  }
  .h-offer {
    width: 380px;
    font-weight: 600;
    font-size: 24px;
  }
  .p-offer {
    margin-top: 0;
    font-size: 18px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: -40px;
  }
  .btn-offer {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    padding-top: 5px;
  }
  .h-titles {
    font-size: 42px;
  }
  .h-titles-ciber {
    font-size: 37px;
  }
  .h-titles-ciber-SGSI {
    font-size: 37px;
  }
  .border-2 {
    width: 24% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 75%;
    margin-top: -20px;
  }
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 290px;
    height: 100%;
    font-weight: 500;
    margin-top: 75px;
  }
  .p-services {
    padding-top: 280px;
    text-align: justify;
    height: 380px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 190px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 10px;
    margin-left: 10px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 10px;
  }
  .col-img-consult {
    width: 280px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .img-consult {
    width: 220px;
  }
  .p-consult {
    margin-top: 0px;
    margin-right: 0px;
    font-size: 19px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 14px;
    width: 150px;
    height: 30px;
    margin-top: 0px;
    padding-top: 5px;
  }
  .border-6 {
    width: 37%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 63%;
    margin-top: -20px;
  }
  .border-8 {
    width: 26%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 74%;
    margin-top: -20px;
  }
  h4 {
    font-size: 19px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .border-6 {
    width: 38%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 62%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 31px;
  }
  .container-fluid-values {
    width: 100%;
    height: 480px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .text-wrap-values {
    font-size: 12px;
    text-align: left;
    width: 95%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 25px;
    margin-left: 80px;
    font-size: 22px;
  }
  .p-values {
    padding-top: 20px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    height: 210px;
  }
  .col-value2 {
    height: 210px;
  }
  .col-value3 {
    height: 210px;
  }
  .border-14 {
    width: 30%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 70%;
    margin-top: -20px;
  }
  .border-16 {
    width: 33%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 67%;
    margin-top: -20px;
  }
  .list-innovation-services {
    padding-top: 20px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 17px;
    list-style-type: "✓ ";
  }
  .border-10 {
    width: 16%;
    margin-top: -34px;
  }
  .border-11 {
    margin-left: 84%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 490px;
  }
  .list-networks-services {
    padding-top: 20px;
    padding-right: 10px;
    font-size: 17px;
  }
  .border-12 {
    width: 4%;
    margin-top: -34px;
  }
  .border-13 {
    margin-left: 96%;
    margin-top: -20px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 17px;
  }
  .border-18 {
    width: 14%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 86%;
    margin-top: -20px;
  }
  .container-fluid-ciber-specialized {
    height: 950px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 30px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 18px;
  }
  .container-fluid-ciber-quality {
    height: 850px;
  }
  .row-ciber-quality {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 30px;
  }
  .container-fluid-services-form {
    height: 450px;
  }
  .container-diagnostic-form {
    margin-left: 45px;
    width: 720px;
    height: 130px;
  }
  .soon1 {
    font-size: 19px;
    padding-top: 25px;
  }
  .btn-diagnostic-form {
    font-size: 17px;
    width: 200px;
    height: 40px;
    margin-top: 50px;
    padding-top: 10px;
    margin-left: 520px;
  }
  .container-content-policy {
    margin-left: 70px;
    margin-right: 70px;
  }
  .h-titles-policy {
    font-size: 36px;
  }
  .table-policy {
    width: 600px;
  }
}
@media (max-width: 800px) {
  .img-company {
    width: 250px;
  }
  .col-company {
    margin-left: 30px;
  }
  .p-about-us {
    font-size: 17px;
  }
  .container-fluid-mission-vision {
    padding-top: 0;
    height: 440px;
  }
  .row-m-v {
    margin-top: 30px;
  }
  .col-mission {
    height: 450px;
  }
  .col-vision {
    height: 450px;
  }
  .text-wrap-mission {
    background-image: url("../public/images/about-us/mision-800.png");
    width: 300px;
    font-size: 13px;
    height: 410px;
    margin-top: -40px;
  }
  .text-wrap-vision {
    background-image: url("../public/images/about-us/vision-800.png");
    width: 300px;
    font-size: 13px;
    height: 410px;
    margin-top: -40px;
  }
  .text-wrap-mission .values {
    font-size: 26px;
    margin-top: 170px;
  }
  .text-wrap-vision .values {
    font-size: 26px;
    margin-top: 170px;
  }
  .text-wrap-mission .p-m-v {
    width: 250px;
    margin-left: 20px;
  }
  .text-wrap-vision .p-m-v {
    width: 250px;
    margin-left: 20px;
  }
  .border-12 {
    display: none;
  }
  .border-13 {
    display: none;
  }
  .border-18 {
    width: 10%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 90%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    padding-left: 30px;
    padding-right: 30px;
    height: 1150px;
  }
  .row-ciber {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: -20px;
  }
  .img-ciber {
    width: 300px;
  }
  .col-img-ciber {
    padding-top: 30px;
    padding-left: 170px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 19px;
  }
  .container-fluid-ciber-specialized {
    padding-left: 30px;
    padding-right: 30px;
    height: 1000px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 18px;
    width: 500px;
  }
  .container-fluid-ciber-quality {
    padding-left: 30px;
    padding-right: 30px;
    height: 1130px;
  }
  .row-ciber-quality {
    display: none;
  }
  .row-ciber-quality2 {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    margin-left: 50px;
    flex-shrink: 0;
    max-width: 100%;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    font-size: 24px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 30px;
    padding-right: 0;
    padding-left: 170px;
  }
  .img-map {
    width: 140px;
  }
  .h-titles-attention1 {
    font-size: 30px;
  }
  .h-titles-attention2 {
    font-size: 40px;
  }
  .h-titles-attention3 {
    font-size: 30px;
  }
  .h-titles-attention4 {
    font-size: 40px;
  }
  .icon {
    width: 63px;
    height: 63px;
  }
  .wpp-contact {
    font-size: 28px;
  }
  .mail-contact {
    font-size: 28px;
  }
  .contact-form {
    max-width: 700px;
    margin-top: 50px;
    margin-left: 50px;
  }
  #contact-titles {
    font-size: 19px;
  }
  input,
  textarea {
    font-size: 15px;
    width: 90%;
  }
  #input-submit {
    font-size: 17px;
    width: 654px;
  }
  .container-fluid-services-form {
    height: 450px;
  }
  .container-diagnostic-form {
    margin-left: 95px;
    width: 600px;
    height: 150px;
  }
  .soon1 {
    font-size: 19px;
    padding-top: 25px;
  }
  .btn-diagnostic-form {
    font-size: 17px;
    width: 200px;
    height: 40px;
    margin-top: 50px;
    padding-top: 10px;
    margin-left: 400px;
  }
}
@media (max-width: 780px) {
  .h-titles-ciber-SGSI {
    display: none;
  }
  .h-titles-ciber-SGSI2 {
    display: block;
    font-size: 37px;
    text-align: center;
    color: #09294f;
    margin-top: 90px;
    padding-bottom: 10px;
  }
  .container-fluid-ciber-SGSI {
    height: 1200px;
  }
}
@media (max-width: 775px) {
  .container-fluid-offer {
    height: 750px;
  }
  .img-fluid-offer {
    width: 300px;
    margin-left: 220px;
    margin-top: 0;
  }
  .h-offer {
    width: 450px;
    margin-top: 80px;
    margin-left: 50px;
    font-weight: 600;
    font-size: 26px;
    text-align: center;
  }
  .p-offer {
    margin-top: 0;
    font-size: 20px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 100px;
    margin-right: 100px;
    margin-top: -30px;
    padding: 0;
  }
  .btn-offer {
    font-size: 15px;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    padding-top: 5px;
    margin-left: 200px;
  }
  .h-titles {
    font-size: 40px;
  }
  .h-titles-ciber {
    font-size: 35px;
  }
  .h-titles-ciber-SGSI {
    font-size: 35px;
  }
  .border-2 {
    width: 22% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 77%;
    margin-top: -20px;
  }
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 280px;
    height: 100%;
    font-weight: 500;
    margin-top: 75px;
  }
  .p-services {
    padding-top: 265px;
    text-align: justify;
    height: 360px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 190px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .row-consult {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-right: 0px;
    margin-left: 0px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 10px;
  }
  .col-img-consult {
    width: 250px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .img-consult {
    width: 200px;
  }
  .p-consult {
    margin-top: 0px;
    margin-right: 0px;
    font-size: 17px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 14px;
    width: 150px;
    height: 30px;
    margin-top: 0px;
    padding-top: 5px;
    margin-left: 0;
  }
  h4 {
    font-size: 19px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 30px;
  }
  .row-infraestructure {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 100px;
  }
  .container-fluid-infra {
    height: 800px;
  }
  .list-infraestructure-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 18px;
    list-style-type: "✓ ";
    width: auto;
  }
  .col-img-infra {
    display: flex;
    padding-top: 0;
    justify-content: center;
  }
  .img-infra {
    width: 250px;
  }
  .border-14 {
    width: 29%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 71%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 820px;
  }
  .row-innovation {
    display: none;
  }
  .row-innovation2 {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 100px;
    margin-left: 100px;
  }
  .list-innovation-services {
    padding-top: 50px;
    padding-left: 0;
    font-size: 18px;
    list-style-type: "✓ ";
    width: auto;
  }
  .col-img-innova {
    display: flex;
    padding-top: 0;
    justify-content: center;
  }
  .img-innova {
    width: 250px;
  }
  .border-10 {
    width: 15%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 85%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 740px;
  }
  .list-networks-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 18px;
  }
  .container-fluid-air {
    height: 720px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 18px;
  }
  .img-ciber {
    width: 280px;
  }
  .col-img-ciber {
    padding-left: 180px;
  }
  .container-lines {
    margin-left: 60px;
  }
  .row-attention {
    margin-left: 60px;
  }
  #input-submit {
    font-size: 17px;
    width: 640px;
  }
  .h-titles-policy {
    font-size: 34px;
  }
  .title-policy {
    font-size: 26px;
  }
  .container-politicy {
    margin: 10px;
    margin-top: 40px;
  }
  .table-policy {
    width: 570px;
  }
  .title-column {
    font-size: 15px;
  }
  .content-column {
    font-size: 13px;
  }
}
@media (max-width: 760px) {
  #input-submit {
    font-size: 17px;
    width: 626px;
  }
}
@media (max-width: 740px) {
  .text-wrap-service {
    font-size: 16px;
    width: 270px;
    height: 100%;
    font-weight: 500;
    margin-top: 75px;
  }
  .p-services {
    padding-top: 265px;
    text-align: justify;
    height: 360px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 160px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .container-fluid-company {
    height: 600px;
  }
  .row-company {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 400px;
    margin-left: 120px;
  }
  .p-about-us {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 20px;
    text-align: justify;
    width: auto;
  }
  .border-4 {
    width: 26%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 74%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 29px;
  }
  .container-fluid-values {
    width: 100%;
    height: 1250px;
    padding-top: 30px;
    background-color: #d9d9d9;
  }
  .row-values {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    height: 1000px;
  }
  .text-wrap-values {
    font-size: 18px;
    text-align: left;
    width: 50%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 35px;
    margin-left: 100px;
    font-size: 26px;
  }
  .p-values {
    padding-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    background-size: 350px;
    margin-bottom: 30px;
    width: 100%;
  }
  .col-value2 {
    background-size: 350px;
    margin-bottom: 30px;
    width: 100%;
  }
  .col-value3 {
    background-size: 350px;
    margin-bottom: 30px;
    width: 100%;
  }
  .container-fluid-mission-vision {
    height: 1020px;
  }
  .row-m-v {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    margin-bottom: 0;
    height: 1000px;
  }
  .col-mission {
    width: auto;
  }
  .col-vision {
    margin-top: 20px;
    width: auto;
  }
  .text-wrap-mission {
    width: 400px;
    font-size: 18px;
    height: 460px;
    margin-top: -40px;
  }
  .text-wrap-vision {
    width: 400px;
    font-size: 18px;
    height: 460px;
    margin-top: -40px;
  }
  .text-wrap-mission .values {
    font-size: 30px;
    margin-top: 170px;
  }
  .text-wrap-vision .values {
    font-size: 30px;
    margin-top: 170px;
  }
  .text-wrap-mission .p-m-v {
    width: 350px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .text-wrap-vision .p-m-v {
    width: 350px;
    margin-left: 20px;
    margin-top: 20px;
  }
  .border-14 {
    width: 28%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .border-16 {
    width: 32%;
    margin-top: -32px;
  }
  .border-17 {
    margin-left: 68%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 830px;
  }
  .border-10 {
    width: 14%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 86%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 740px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 18px;
  }
  .border-18 {
    width: 11%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 89%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 1150px;
  }
  .row-ciber {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: -20px;
  }
  .img-ciber {
    width: 280px;
  }
  .col-img-ciber {
    padding-top: 30px;
    padding-left: 180px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 18px;
  }
  .container-fluid-ciber-quality {
    height: 1100px;
  }
  .row-ciber-quality2 {
    margin-right: 30px;
    margin-left: 30px;
    flex-shrink: 0;
    max-width: 100%;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    font-size: 23px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 30px;
    padding-right: 0;
    padding-left: 170px;
  }
  .img-map {
    width: 120px;
  }
  .h-titles-attention1 {
    font-size: 27px;
  }
  .h-titles-attention2 {
    font-size: 37px;
  }
  .h-titles-attention3 {
    font-size: 27px;
  }
  .h-titles-attention4 {
    font-size: 37px;
  }
  .icon {
    width: 60px;
    height: 60px;
  }
  .wpp-contact {
    font-size: 26px;
  }
  .mail-contact {
    font-size: 26px;
  }
  #input-submit {
    font-size: 17px;
    width: 608px;
  }
  .container-diagnostic-form {
    margin-left: 60px;
  }
}
@media (max-width: 737px) {
  .border-slider-ciber {
    width: 400px;
  }
}
@media (max-width: 720px) {
  #input-submit {
    font-size: 17px;
    width: 590px;
  }
  .img-fluid-offer {
    width: 300px;
    margin-left: 200px;
    margin-top: 0;
  }
  .btn-offer {
    font-size: 15px;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    padding-top: 5px;
    margin-left: 180px;
  }
  .col-img-ciber {
    padding-left: 160px;
  }
  .container-content-policy {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media (max-width: 705px) {
  .h-titles-ciber-SGSI {
    display: none;
  }
  #input-submit {
    font-size: 17px;
    width: 573px;
  }
}
@media (max-width: 700px) {
  .row-services {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: 20px;
    margin-left: 50px;
  }
  .text-wrap-service {
    font-size: 16px;
    width: 250px;
    height: 100%;
    font-weight: 500;
    margin-top: 75px;
  }
  .p-services {
    padding-top: 265px;
    text-align: justify;
    height: 345px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 160px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .border-6 {
    width: 36%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 64%;
    margin-top: -20px;
  }
  .border-8 {
    width: 24%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 76%;
    margin-top: -20px;
  }
  h4 {
    font-size: 19px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .container-fluid-company {
    height: 550px;
  }
  .row-company {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 380px;
    margin-left: 100px;
    margin-top: 20px;
  }
  .p-about-us {
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 30px;
    font-size: 19px;
    text-align: justify;
    width: auto;
  }
  .border-4 {
    width: 24%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 76%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 28px;
  }
  .text-wrap-values {
    font-size: 17px;
    text-align: left;
    width: 50%;
    height: 100%;
    font-weight: 500;
  }
  .text-wrap-mission {
    font-size: 17px;
  }
  .text-wrap-vision {
    font-size: 17px;
  }
  .text-wrap-mission .values {
    font-size: 28px;
  }
  .text-wrap-vision .values {
    font-size: 28px;
  }
  .container-fluid-infra {
    height: 800px;
  }
  .list-infraestructure-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 18px;
    list-style-type: "✓ ";
    width: auto;
  }
  .img-infra {
    width: 230px;
  }
  .border-14 {
    width: 27%;
    margin-top: -34px;
  }
  .border-15 {
    margin-left: 73%;
    margin-top: -20px;
  }
  .border-16 {
    width: 31%;
    margin-top: -30px;
  }
  .border-17 {
    margin-left: 69%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 800px;
  }
  .row-innovation {
    display: none;
  }
  .list-innovation-services {
    padding-top: 50px;
    padding-left: 0;
    font-size: 18px;
    list-style-type: "✓ ";
    width: auto;
  }
  .img-innova {
    width: 230px;
  }
  .border-10 {
    width: 11%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 89%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 760px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 18px;
  }
  .border-18 {
    width: 9%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 91%;
    margin-top: -20px;
  }
  .col-img-ciber {
    padding-left: 160px;
  }
  .row-ciber-quality2 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    font-size: 22px;
  }
  .col-quality {
    height: 600px;
    display: flex;
    margin-top: -30px;
  }
  .col-img-quality {
    flex: 0 0 auto;
    width: auto;
    padding-top: 30px;
    padding-right: 0;
    padding-left: 170px;
  }
  .title-form {
    padding-top: 15px;
    font-size: 26px;
    height: 100px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 23px;
  }
  .container-fluid-attention {
    height: 450px;
  }
  .row-attention {
    margin-left: 50px;
  }
  .img-map {
    width: 100px;
  }
  .h-titles-attention1 {
    font-size: 25px;
  }
  .h-titles-attention2 {
    font-size: 35px;
  }
  .h-titles-attention3 {
    font-size: 25px;
  }
  .h-titles-attention4 {
    font-size: 35px;
  }
  .icon {
    width: 57px;
    height: 57px;
  }
  .wpp-contact {
    font-size: 24px;
  }
  .mail-contact {
    font-size: 24px;
  }
  .nav-toggle {
    left: 88%;
  }
  .slider1 {
    height: 350px;
  }
  .slider2 {
    height: 350px;
  }
  .slider3 {
    height: 350px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin-top: -80px;
  }
  .text-wrap-slider {
    width: 95%;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 28px;
    margin-left: 100px;
    margin-top: 90px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 28px;
    margin-left: 100px;
    margin-top: 60px;
  }
  .text-wrap-slider .p-slider-pages {
    font-size: 20px;
    margin-left: 50px;
    margin-right: 30px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 28px;
    margin-left: 50px;
    margin-top: 90px;
  }
  .border-slider-home {
    margin-left: 130px;
    width: 365px;
  }
  .btn-slider {
    position: absolute;
    top: 300px;
    left: 130px;
  }
  .container-slider-about-us {
    height: 400px;
  }
  .container-slider-services {
    height: 400px;
  }
  .container-slider-cybersecurity {
    height: 400px;
  }
  .container-slider-blog {
    height: 400px;
  }
  .container-slider-contact {
    height: 400px;
  }
  .border-slider-about {
    margin-left: 50px;
    width: 540px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 20px;
    margin-left: 100px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 28px;
    margin-left: 50px;
    margin-top: 100px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 20px;
    margin-left: 50px;
    margin-right: 30px;
  }
  .border-slider-home {
    margin-left: 100px;
    width: 320px;
  }
  .btn-slider {
    font-size: 16px;
    width: 150px;
    height: 35px;
    padding-top: 7px;
    position: absolute;
    top: 250px;
    left: 100px;
  }
  .wave-slider2 {
    display: none;
  }
  .wave-slider3 {
    display: block;
    position: relative;
  }
  .container-slider-about-us {
    height: 350px;
  }
  .container-slider-services {
    height: 350px;
  }
  .container-slider-cybersecurity {
    height: 350px;
  }
  .container-slider-blog {
    height: 350px;
  }
  .container-slider-contact {
    height: 350px;
  }
}
@media (max-width: 691px) {
  .container-fluid-ciber-SGSI {
    height: 1200px;
  }
  .container-fluid-services-sp {
    height: 730px;
  }
  .row-services-portfolio2 {
    margin-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .row-services-portfolio3 {
    margin-top: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .service-networks {
    margin-left: 30px;
  }
  .service-air {
    margin-left: 60px;
  }
  .col-icons {
    width: 175px;
    height: 180px;
    border-radius: 120px;
  }
  .service-item {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: center;
    margin-top: 0px;
  }
  .img-services {
    width: 120px;
  }
  .services-link-infra {
    font-size: 28px;
  }
  .services-link-innova {
    font-size: 28px;
  }
  .services-link-net {
    font-size: 28px;
  }
  .services-link-air {
    font-size: 28px;
    margin-left: -25px;
  }
  .container-fluid-services-form {
    height: 420px;
  }
  .container-diagnostic-form {
    margin-left: 75px;
    width: 530px;
    height: 140px;
  }
  .soon1 {
    font-size: 18px;
    padding-top: 25px;
  }
  .btn-diagnostic-form {
    font-size: 16px;
    width: 170px;
    height: 40px;
    margin-top: 50px;
    padding-top: 10px;
    margin-left: 360px;
  }
}
@media (max-width: 680px) {
  .img-fluid-offer {
    margin-left: 170px;
  }
  .col-text-offer {
    margin-right: 50px;
    margin-left: 50px;
    width: auto;
  }
  .btn-offer {
    margin-left: 200px;
  }
  #input-submit {
    font-size: 17px;
    width: 552px;
  }
  .col-img-ciber {
    padding-left: 140px;
  }
  .container-fluid-ciber-specialized {
    height: 1050px;
  }
  .h-titles-policy {
    font-size: 30px;
  }
  .title-policy {
    font-size: 24px;
  }
  .p-content-policy {
    font-size: 16px;
  }
  .table-policy {
    width: 540px;
  }
  .title-column {
    font-size: 15px;
  }
  .content-column {
    font-size: 13px;
  }
  .social-networks-hidden {
    font-size: 19px;
  }
}
@media (max-width: 666px) {
  .border-slider-about {
    margin-left: 50px;
    width: 380px;
  }
}
@media (max-width: 660px) {
  #input-submit {
    font-size: 17px;
    width: 533px;
  }
}
@media (max-width: 650px) {
  .img-fluid-offer {
    margin-left: 150px;
  }
  .h-offer {
    width: 450px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
  }
  .p-offer {
    margin-top: 0;
    font-size: 18px;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: -30px;
    padding: 0;
  }
  .btn-offer {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    padding-top: 5px;
    align-items: center;
    margin-left: 180px;
  }
  .h-titles {
    font-size: 38px;
  }
  .h-titles-ciber {
    font-size: 33px;
  }
  .h-titles-ciber-SGSI2 {
    font-size: 33px;
  }
  .border-2 {
    width: 18% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 81%;
    margin-top: -20px;
  }
  .row-services {
    margin-top: 50px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .text-wrap-service {
    font-size: 18px;
    width: 330px;
    height: 100%;
    font-weight: 500;
    margin-top: 100px;
  }
  .p-services {
    padding-top: 265px;
    text-align: justify;
    height: 390px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 220px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .row-consult {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 10px;
  }
  .col-img-consult {
    width: 300px;
    padding-left: 150px;
    margin-bottom: 30px;
  }
  .img-consult {
    width: 280px;
  }
  .p-consult {
    margin-top: 30px;
    margin-right: 0px;
    font-size: 19px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 16px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 220px;
  }
  .border-8 {
    width: 22%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 78%;
    margin-top: -20px;
  }
  h4 {
    font-size: 18px;
  }
  .container-politics {
    height: 35px;
  }
  .content-foo2 {
    display: flex;
    margin-top: -50px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    justify-content: space-evenly;
    background-color: #dad9d9;
  }
  .icons-social-networks {
    width: 35px;
  }
  .icons-social-networks2 {
    width: 35px;
  }
  .container-fluid-company {
    height: 530px;
  }
  .row-company {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 350px;
    margin-left: 100px;
    margin-top: 20px;
  }
  .p-about-us {
    margin-top: 20px;
    margin-left: 0;
    margin-bottom: 30px;
    font-size: 18px;
    text-align: justify;
    width: auto;
  }
  .border-4 {
    width: 22%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 78%;
    margin-top: -20px;
  }
  .border-6 {
    width: 36%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 64%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 27px;
  }
  .text-wrap-values {
    font-size: 16px;
    text-align: left;
    width: 55%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 35px;
    margin-left: 100px;
    font-size: 25px;
  }
  .p-values {
    padding-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .container-fluid-mission-vision {
    height: 930px;
  }
  .row-m-v {
    margin-top: 60px;
    height: 900px;
  }
  .col-vision {
    margin-top: 50px;
  }
  .text-wrap-mission {
    width: 350px;
    font-size: 16px;
    height: 430px;
    margin-top: -20px;
  }
  .text-wrap-vision {
    width: 350px;
    font-size: 16px;
    height: 430px;
    margin-top: -20px;
  }
  .text-wrap-mission .values {
    font-size: 26px;
  }
  .text-wrap-vision .values {
    font-size: 26px;
  }
  .text-wrap-mission .p-m-v {
    width: 300px;
  }
  .text-wrap-vision .p-m-v {
    width: 300px;
  }
  .list-infraestructure-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 17px;
    list-style-type: "✓ ";
    width: auto;
  }
  .border-14 {
    width: 26%;
    margin-top: -32px;
  }
  .border-15 {
    margin-left: 74%;
    margin-top: -20px;
  }
  .border-16 {
    width: 30%;
    margin-top: -30px;
  }
  .border-17 {
    margin-left: 70%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 830px;
  }
  .list-innovation-services {
    padding-top: 50px;
    padding-left: 0;
    font-size: 17px;
    list-style-type: "✓ ";
    width: auto;
  }
  .border-10 {
    width: 10%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 90%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 720px;
  }
  .list-networks-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 17px;
  }
  .container-fluid-air {
    height: 760px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 17px;
  }
  .border-18 {
    width: 7%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 93%;
    margin-top: -20px;
  }
  .container-fluid-ciber-SGSI {
    height: 1100px;
  }
  .row-ciber {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: -20px;
  }
  .img-ciber {
    width: 260px;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 17px;
  }
  .container-fluid-ciber-specialized {
    height: 970px;
  }
  .row-ciber-specialized {
    display: flex;
    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 20px;
  }
  .p-ciber-specialized {
    margin-top: 40px;
    margin-right: 10px;
    margin-left: 10px;
    font-size: 17px;
  }
  .p-ciber-quality {
    margin-top: 40px;
    margin-right: 0;
    margin-left: 30px;
    font-size: 17px;
    width: 400px;
  }
  .container-fluid-ciber-quality {
    height: 1100px;
  }
  .container-fluid-attention {
    height: 400px;
  }
  .container-lines {
    margin-left: 40px;
  }
  .row-attention {
    margin-left: 40px;
  }
  .img-map {
    width: 80px;
  }
  .h-titles-attention1 {
    font-size: 23px;
  }
  .h-titles-attention2 {
    font-size: 33px;
  }
  .h-titles-attention3 {
    font-size: 23px;
  }
  .h-titles-attention4 {
    font-size: 33px;
  }
  .icon {
    width: 55px;
    height: 55px;
  }
  .wpp-contact {
    font-size: 22px;
  }
  .mail-contact {
    font-size: 22px;
  }
  .col-img-quality {
    padding-left: 150px;
  }
  .container-content-policy {
    margin-left: 30px;
    margin-right: 30px;
  }
}
@media (max-width: 640px) {
  #input-submit {
    font-size: 17px;
    width: 515px;
  }
  .container-fluid-ciber-specialized {
    height: 1000px;
  }
  .container-fluid-services-form {
    height: 440px;
  }
  .container-diagnostic-form {
    margin-left: 75px;
    width: 466px;
    height: 165px;
  }
  .soon1 {
    font-size: 18px;
    padding-top: 25px;
  }
  .btn-diagnostic-form {
    font-size: 16px;
    width: 170px;
    height: 40px;
    margin-top: 50px;
    padding-top: 10px;
    margin-left: 295px;
  }
}
@media (max-width: 636px) {
  .border-slider-services {
    width: 385px;
  }
}
@media (max-width: 620px) {
  #input-submit {
    font-size: 17px;
    width: 497px;
  }
  .container-fluid-ciber-SGSI {
    height: 1180px;
  }
  .col-img-ciber {
    padding-left: 120px;
  }
  .table-policy {
    width: 520px;
  }
}
@media (max-width: 600px) {
  .img-fluid-offer {
    margin-left: 130px;
  }
  .btn-offer {
    margin-left: 160px;
  }
  .border-10 {
    width: 8%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 92%;
    margin-top: -20px;
  }
  .border-18 {
    width: 6%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 94%;
    margin-top: -20px;
  }
  .col-img-quality {
    padding-left: 120px;
  }
  .title-form {
    padding-top: 15px;
    font-size: 25px;
    height: 100px;
  }
  .title-form2 {
    padding-top: 15px;
    font-size: 28px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 22px;
  }
  .row-attention {
    margin-left: 20px;
  }
  #input-submit {
    font-size: 17px;
    width: 475px;
  }
  .img-company {
    width: 350px;
    margin-left: 80px;
    margin-top: 20px;
  }
  .container-fluid-services-sp {
    height: 660px;
  }
  .row-services-portfolio2 {
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .row-services-portfolio3 {
    margin-top: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .service-networks {
    margin-left: 30px;
  }
  .service-air {
    margin-left: 50px;
  }
  .col-icons {
    width: 155px;
    height: 160px;
    border-radius: 120px;
  }
  .img-services {
    width: 100px;
  }
  .services-link-infra {
    font-size: 26px;
  }
  .services-link-innova {
    font-size: 26px;
  }
  .services-link-net {
    font-size: 26px;
    margin-left: 5px;
  }
  .services-link-air {
    font-size: 26px;
    margin-left: -30px;
  }
}
@media (max-width: 580px) {
  .img-consult {
    width: 250px;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 180px;
  }
  .border-8 {
    width: 20%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 80%;
    margin-top: -20px;
  }
  .container-fluid-company {
    height: 530px;
  }
  .row-company {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 330px;
    margin-left: 80px;
  }
  .p-about-us {
    font-size: 18px;
  }
  .border-4 {
    width: 20%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 80%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 26px;
  }
  .text-wrap-values {
    font-size: 16px;
    text-align: left;
    width: 60%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 35px;
    margin-left: 100px;
    font-size: 25px;
  }
  .p-values {
    padding-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .border-6 {
    width: 35%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 65%;
    margin-top: -20px;
  }
  .img-infra {
    width: 210px;
  }
  .border-14 {
    width: 24%;
    margin-top: -32px;
  }
  .border-15 {
    margin-left: 76%;
    margin-top: -20px;
  }
  .border-16 {
    width: 29%;
    margin-top: -30px;
  }
  .border-17 {
    margin-left: 71%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 830px;
  }
  .img-innova {
    width: 210px;
  }
  .border-10 {
    width: 6%;
    margin-top: -32px;
  }
  .border-11 {
    margin-left: 94%;
    margin-top: -20px;
  }
  .container-fluid-air {
    height: 780px;
  }
  .border-18 {
    width: 5%;
    margin-top: -32px;
  }
  .border-19 {
    margin-left: 95%;
    margin-top: -20px;
  }
  .col-img-ciber {
    padding-left: 100px;
  }
  .container-fluid-ciber-specialized {
    height: 1050px;
  }
  .container-fluid-ciber-quality {
    height: 1080px;
  }
  .row-ciber-quality2 {
    margin-right: 10px;
    margin-left: 10px;
  }
  .title-quality {
    margin-top: 20px;
    margin-right: 0;
    margin-left: 0;
    font-size: 22px;
  }
  .col-quality {
    height: 600px;
    width: auto;
    margin-left: 0;
  }
  .col-img-quality {
    padding-left: 110px;
  }
  .title-form {
    padding-top: 15px;
    font-size: 24px;
    height: 100px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 21px;
  }
  .container-fluid-attention {
    height: 850px;
  }
  .col-icon-map {
    display: none;
  }
  .col-icon-map2 {
    display: block;
    flex: 0 0 auto;
    width: auto;
  }
  .row-attention {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
  }
  .container-lines {
    margin-left: 0;
  }
  .img-map {
    margin-left: 170px;
    width: 150px;
    margin-top: 30px;
  }
  .h-titles-attention1 {
    font-size: 35px;
    margin-top: 30px;
    margin-left: -20px;
  }
  .h-titles-attention2 {
    font-size: 45px;
  }
  .h-titles-attention3 {
    font-size: 35px;
    margin-top: 80px;
  }
  .h-titles-attention4 {
    font-weight: 600;
    font-size: 45px;
    text-align: center;
    color: #000;
  }
  .wpp-contact {
    font-size: 27px;
  }
  .mail-contact {
    font-size: 27px;
  }
  #input-submit {
    font-size: 17px;
    width: 458px;
  }
  .nav-toggle {
    left: 86%;
  }
  .container-diagnostic-form {
    margin-left: 50px;
  }
  .h-titles-policy {
    font-size: 28px;
  }
  .table-policy {
    width: 480px;
  }
  .title-column {
    font-size: 14px;
  }
  .content-column {
    font-size: 12px;
  }
  .social-networks-hidden {
    font-size: 18px;
  }
}
@media (max-width: 552px) {
  .container-fluid-offer {
    height: 670px;
  }
  .row-value-offer {
    display: block;
  }
  .col-offer {
    flex: 1 0;
    margin-left: 20px;
    margin-right: 20px;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 0;
    margin-right: 30px;
    padding: 0;
    width: auto;
  }
  .img-fluid-offer {
    width: 250px;
    margin-left: 90px;
  }
  .h-offer {
    width: 450px;
    margin-left: -26px;
    font-weight: 600;
    font-size: 23px;
    text-align: center;
  }
  .p-offer {
    margin-top: 0;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 30px;
    margin-right: 30px;
    padding: 0;
    width: auto;
  }
  .btn-offer {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 150px;
    height: 30px;
    margin-top: 20px;
    padding-top: 5px;
    align-items: center;
    margin-left: 130px;
  }
  .h-titles {
    font-size: 36px;
  }
  .h-titles-ciber {
    font-size: 31px;
  }
  .h-titles-ciber-SGSI2 {
    font-size: 31px;
    margin-top: 60px;
  }
  .border-2 {
    width: 17% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 82%;
    margin-top: -20px;
  }
  .row-consult {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
    flex-shrink: 0;
    max-width: 100%;
    padding-right: 10px;
    margin-bottom: 50px;
  }
  .col-img-consult {
    width: 300px;
    padding-left: 140px;
    margin-bottom: 30px;
  }
  .img-consult {
    width: 230px;
  }
  .p-consult {
    margin-top: 30px;
    margin-right: 0px;
    font-size: 18px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 160px;
  }
  .border-8 {
    width: 19%;
    margin-top: -32px;
  }
  .border-9 {
    margin-left: 81%;
    margin-top: -20px;
  }
  .container-footer {
    width: 100%;
    height: 110px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #09294f;
  }
  .content-foo-legal {
    display: flex;
    flex-direction: column;
    text-align: start;
  }
  .content-foo-social {
    display: flex;
    flex-direction: row;
    text-align: start;
  }
  .content-foo2 {
    display: none;
  }
  .wave2 {
    display: none;
  }
  .social-networks-hidden {
    font-size: 17px;
  }
  .icons-social-networks {
    width: 50px;
    justify-content: center;
  }
  .icons-social-networks2 {
    width: 50px;
    display: flex;
  }
  .footer-copyright {
    border-top: 4px solid #09294f;
    text-align: center;
    margin: 0;
    color: #09294f;
  }
  .container-fluid-company {
    height: 500px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 320px;
    margin-left: 60px;
  }
  .p-about-us {
    font-size: 17px;
    margin-top: 15px;
  }
  .border-6 {
    width: 34%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 66%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 25px;
  }
  .container-fluid-infra {
    height: 740px;
  }
  .list-infraestructure-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 17px;
    list-style-type: "✓ ";
    width: auto;
  }
  .img-infra {
    width: 210px;
  }
  .border-14 {
    width: 23%;
    margin-top: -32px;
  }
  .border-15 {
    margin-left: 77%;
    margin-top: -20px;
  }
  .border-16 {
    width: 28%;
    margin-top: -30px;
  }
  .border-17 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 800px;
  }
  .img-innova {
    width: 210px;
  }
  .border-10 {
    width: 6%;
    margin-top: -30px;
  }
  .border-11 {
    margin-left: 94%;
    margin-top: -20px;
  }
  .border-18 {
    display: none;
  }
  .border-19 {
    display: none;
  }
  .container-fluid-ciber-SGSI {
    height: 1100px;
  }
  .row-ciber {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: -20px;
  }
  .img-ciber {
    width: 250px;
  }
  .col-img-ciber {
    padding-top: 10px;
    padding-left: 105px;
  }
  .p-ciber {
    margin-top: 30px;
    margin-right: 10px;
    font-size: 16px;
  }
  .container-fluid-ciber-specialized {
    height: 980px;
  }
  .p-ciber-specialized {
    font-size: 16px;
  }
  .p-ciber-quality {
    font-size: 16px;
  }
  .title-form {
    padding-top: 15px;
    font-size: 23px;
    height: 100px;
  }
  .title-form2 {
    padding-top: 15px;
    font-size: 26px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 20px;
  }
  .img-map {
    margin-left: 170px;
    width: 130px;
    margin-top: 30px;
  }
  .h-titles-attention1 {
    font-size: 33px;
  }
  .h-titles-attention2 {
    font-size: 43px;
  }
  .h-titles-attention3 {
    font-size: 33px;
    margin-top: 80px;
  }
  .h-titles-attention4 {
    font-size: 43px;
  }
  .wpp-contact {
    font-size: 25px;
  }
  .mail-contact {
    font-size: 25px;
  }
  #input-submit {
    font-size: 17px;
    width: 432px;
  }
  .container-fluid-services-form {
    height: 440px;
  }
  .container-diagnostic-form {
    margin-left: 75px;
    width: 390px;
    height: 175px;
  }
  .soon1 {
    font-size: 17px;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .btn-diagnostic-form {
    font-size: 15px;
    width: 160px;
    height: 40px;
    margin-top: 40px;
    padding-top: 10px;
    margin-left: 230px;
  }
  .h-titles-policy {
    font-size: 26px;
  }
  .table-policy {
    width: 450px;
  }
  .title-policy {
    font-size: 22px;
  }
  .p-content-policy {
    font-size: 15px;
  }
  .title-column {
    font-size: 14px;
  }
  .content-column {
    font-size: 12px;
  }
  .social-networks-hidden {
    font-size: 18px;
  }
}
@media (max-width: 531px) {
  .border-6 {
    width: 32%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 68%;
    margin-top: -20px;
  }
  .border-10 {
    display: none;
  }
  .border-11 {
    display: none;
  }
  .container-fluid-networks {
    height: 750px;
  }
  #contact-titles {
    font-size: 18px;
  }
  input,
  textarea {
    font-size: 14px;
    width: 90%;
  }
  #input-submit {
    font-size: 16px;
    width: 413px;
  }
  .input1 {
    display: none;
  }
  .input2 {
    display: block;
  }
  textarea {
    height: 153px;
  }
  .text-wrap-slider .title-slider-home3 {
    width: 90%;
  }
}
@media (max-width: 529px) {
  .container-fluid-ciber-SGSI {
    height: 1150px;
  }
}
@media (max-width: 520px) {
  .col-img-quality {
    padding-left: 100px;
  }
  .col-img-ciber {
    padding-left: 90px;
  }
  .h-titles-policy {
    font-size: 24px;
  }
  .table-policy {
    width: 400px;
  }
  .title-policy {
    font-size: 21px;
  }
  .p-content-policy {
    font-size: 15px;
  }
  .title-column {
    font-size: 13px;
  }
  .content-column {
    font-size: 11px;
  }
}
@media (max-width: 518px) {
  .border-slider-ciber {
    width: 375px;
  }
}
@media (max-width: 515px) {
  .border-slider-contact {
    width: 260px;
  }
}
@media (max-width: 512px) {
  .container-fluid-ciber-specialized {
    height: 1000px;
  }
}
@media (max-width: 510px) {
  .img-fluid-offer {
    margin-left: 75px;
  }
  .btn-offer {
    margin-left: 120px;
  }
  .border-14 {
    width: 22%;
    margin-top: -32px;
  }
  .border-15 {
    margin-left: 78%;
    margin-top: -20px;
  }
  .col-img-ciber {
    padding-left: 75px;
  }
  .container-fluid-services-form {
    height: 350px;
  }
  .title-form {
    padding-top: 10px;
    font-size: 21px;
    height: 75px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 18px;
  }
  .container-fluid-attention {
    height: 780px;
  }
  .img-map {
    margin-left: 150px;
    width: 130px;
    margin-top: 10px;
  }
  .h-titles-attention1 {
    font-size: 30px;
  }
  .h-titles-attention2 {
    font-size: 40px;
  }
  .h-titles-attention3 {
    font-size: 30px;
    margin-top: 60px;
  }
  .h-titles-attention4 {
    font-size: 40px;
  }
  .wpp-contact {
    font-size: 23px;
  }
  .mail-contact {
    font-size: 23px;
  }
  #input-submit {
    font-size: 16px;
    width: 392px;
  }
  .nav-toggle {
    left: 84%;
  }
  .slider1 {
    height: 350px;
  }
  .slider2 {
    height: 350px;
  }
  .slider3 {
    height: 350px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin-top: -100px;
  }
  .text-wrap-slider {
    width: 90%;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 26px;
    margin-left: 80px;
    margin-top: 90px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 26px;
    margin-left: 80px;
    margin-top: 60px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 26px;
    margin-left: 50px;
    margin-top: 90px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 18px;
    margin-left: 80px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 18px;
  }
  .border-slider-home {
    margin-left: 80px;
    width: 320px;
  }
  .border-slider-about {
    width: 350px;
  }
  .btn-slider {
    font-size: 15px;
    position: absolute;
    top: 250px;
    left: 80px;
  }
  .wave-slider3 {
    display: none;
  }
  .wave-slider4 {
    display: block;
    position: relative;
  }
  .border-slider-services {
    width: 360px;
  }
  .border-slider-blog {
    width: 280px;
  }
  .border-slider-contact {
    width: 365px;
  }
  .container-fluid-services-sp {
    height: 620px;
  }
  .row-services-portfolio2 {
    margin-top: 20px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .row-services-portfolio3 {
    margin-top: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .service-networks {
    margin-left: 40px;
  }
  .service-air {
    margin-left: 50px;
  }
  .col-icons {
    width: 135px;
    height: 140px;
    border: 10px solid #09294f;
  }
  .img-services {
    width: 100px;
  }
  .services-link-infra {
    font-size: 24px;
    padding-left: 0;
    padding-right: 0;
  }
  .services-link-innova {
    font-size: 24px;
    padding-left: 0;
    padding-right: 0;
  }
  .services-link-net {
    font-size: 24px;
    margin-left: 15px;
    padding-left: 0;
    padding-right: 0;
  }
  .services-link-air {
    font-size: 24px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .container-fluid-services-form {
    height: 400px;
  }
  .container-diagnostic-form {
    margin-left: 55px;
    width: 380px;
    height: 180px;
    margin-top: -10px;
  }
  .soon1 {
    font-size: 17px;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .btn-diagnostic-form {
    font-size: 15px;
    width: 160px;
    height: 40px;
    margin-top: 40px;
    padding-top: 10px;
    margin-left: 220px;
  }
}

@media (max-width: 480px) {
  .img-fluid-offer {
    width: 250px;
    margin-left: 80px;
  }
  .h-offer {
    width: auto;
    margin-left: 0px;
    font-weight: 600;
    text-align: center;
  }
  .p-offer {
    width: auto;
    margin-top: 0;
    text-align: justify;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: -30px;
    padding: 0;
  }
  .btn-offer {
    font-size: 15px;
    margin-top: 20px;
    margin-left: 155px;
  }
  .h-titles {
    font-size: 34px;
  }
  .h-titles-ciber {
    font-size: 29px;
  }
  .h-titles-ciber-SGSI2 {
    font-size: 29px;
  }
  .border-2 {
    width: 16% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 84%;
    margin-top: -20px;
  }
  .col-img-consult {
    width: 300px;
    padding-left: 120px;
    margin-bottom: 30px;
  }
  .img-consult {
    width: 230px;
  }
  .p-consult {
    margin-top: 30px;
    margin-right: 0px;
    font-size: 18px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 140px;
  }
  .text-wrap-service {
    font-size: 17px;
    width: 320px;
    height: 100%;
    font-weight: 500;
    margin-top: 100px;
  }
  .p-services {
    padding-top: 265px;
    text-align: justify;
    height: 390px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 220px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 16px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .border-8 {
    width: 17%;
    margin-top: -27px;
  }
  .border-9 {
    margin-left: 83%;
    margin-top: -20px;
  }
  .content-foo-social {
    display: flex;
    flex-direction: row;
    text-align: start;
  }
  .content-foo-legal {
    font-size: 15px;
  }
  h4 {
    font-size: 17px;
  }
  .content-foo2 {
    display: none;
  }
  .wave2 {
    display: none;
  }
  .social-networks {
    display: none;
  }
  .social-networks-hidden {
    font-size: 16px;
  }
  .icons-social-networks {
    width: 43px;
  }
  .icons-social-networks2 {
    width: 43px;
  }
  .footer-copyright {
    border-top: 4px solid #09294f;
    text-align: center;
    margin: 0;
    color: #09294f;
  }
  .container-fluid-company {
    height: 480px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: auto;
  }
  .img-company {
    width: 300px;
    margin-left: 40px;
  }
  .p-about-us {
    font-size: 16px;
    margin-top: 0;
  }
  .border-4 {
    width: 18%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 82%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
  }
  .container-fluid-values {
    width: 100%;
    height: 1130px;
    padding-top: 30px;
  }
  .row-values {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-left: -10px;
    height: 900px;
  }
  .text-wrap-values {
    font-size: 16px;
    text-align: left;
    width: 70%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: 100px;
    font-size: 24px;
  }
  .p-values {
    padding-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    background-size: 320px;
    margin-bottom: 30px;
  }
  .col-value2 {
    background-size: 320px;
    margin-bottom: 30px;
  }
  .col-value3 {
    background-size: 320px;
    margin-bottom: 30px;
  }
  .row-infraestructure {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 50px;
    margin-left: 50px;
  }
  .container-fluid-infra {
    height: 700px;
  }
  .list-infraestructure-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 16px;
    list-style-type: "✓ ";
    width: auto;
  }
  .img-infra {
    width: 180px;
  }
  .border-14 {
    width: 20%;
    margin-top: -29px;
  }
  .border-15 {
    margin-left: 80%;
    margin-top: -20px;
  }
  .border-16 {
    width: 27%;
    margin-top: -28px;
  }
  .border-17 {
    margin-left: 73%;
    margin-top: -20px;
  }
  .container-fluid-innova {
    height: 770px;
  }
  .row-innovation2 {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 50px;
    margin-left: 50px;
  }
  .list-innovation-services {
    padding-top: 40px;
    padding-left: 0;
    font-size: 16px;
    list-style-type: "✓ ";
    width: auto;
  }
  .img-innova {
    width: 180px;
  }
  .container-fluid-networks {
    height: 710px;
  }
  .list-networks-services {
    padding-top: 20px;
    padding-left: 0;
    font-size: 16px;
  }
  .container-fluid-air {
    height: 760px;
  }
  .list-air-services {
    padding-top: 60px;
    padding-right: 30px;
    padding-left: 0;
    font-size: 16px;
  }
  .container-fluid-ciber-SGSI {
    padding-left: 0;
    padding-right: 0;
    height: 1050px;
  }
  .row-ciber {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: -20px;
  }
  .img-ciber {
    width: 250px;
  }
  .col-img-ciber {
    display: flex;
    justify-content: center;
  }
  .p-ciber {
    margin-top: 40px;
    margin-right: 10px;
    font-size: 15px;
  }
  .p-ciber-specialized {
    font-size: 15px;
  }
  .p-ciber-quality {
    font-size: 15px;
    width: 350px;
  }
  .container-fluid-ciber-specialized {
    height: 900px;
    padding-left: 0;
    padding-right: 0;
  }
  .container-fluid-ciber-quality {
    height: 1000px;
    padding-left: 0;
    padding-right: 0;
  }
  .col-img-quality {
    padding-left: 0;
    padding-right: 0;
    display: flex;
    justify-content: center;
  }
  .container-fluid-services-form {
    height: 350px;
  }
  .title-form {
    padding-top: 10px;
    font-size: 20px;
    height: 70px;
  }
  .title-form2 {
    padding-top: 17px;
    font-size: 25px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 17px;
  }
  .container-fluid-contact-form {
    height: 680px;
  }
  .half {
    margin-top: 20px;
  }
  #input-submit {
    font-size: 16px;
    width: 366px;
    margin-top: 30px;
  }
  .container-fluid-offer {
    height: 660px;
  }
  .slider1 {
    height: 330px;
  }
  .slider2 {
    height: 330px;
  }
  .slider3 {
    height: 330px;
  }
  .carousel-control-next,
  .carousel-control-prev {
    margin-top: -120px;
  }
  .text-wrap-slider {
    width: 90%;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 24px;
    margin-left: 80px;
    margin-top: 90px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 24px;
    margin-left: 80px;
    margin-top: 60px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 16px;
    margin-left: 80px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 16px;
    margin-right: 0;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 24px;
    margin-left: 50px;
  }
  .border-slider-home {
    margin-left: 80px;
    width: 275px;
  }
  .btn-slider {
    font-size: 14px;
    width: 130px;
    height: 30px;
    position: absolute;
    top: 230px;
    left: 80px;
  }
  .container-slider-about-us {
    height: 330px;
  }
  .container-slider-services {
    height: 330px;
  }
  .container-slider-cybersecurity {
    height: 330px;
  }
  .container-slider-blog {
    height: 330px;
  }
  .container-slider-contact {
    height: 330px;
  }
  .border-slider-about {
    width: 325px;
  }
  .border-slider-services {
    width: 330px;
  }
  .border-slider-ciber {
    width: 340px;
  }
  .border-slider-blog {
    width: 260px;
  }
  .border-slider-contact {
    width: 340px;
  }
  .container-fluid-services-form {
    height: 380px;
  }
  .container-diagnostic-form {
    margin-left: 55px;
    width: 360px;
    height: 160px;
    margin-top: -10px;
  }
  .soon1 {
    font-size: 16px;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .btn-diagnostic-form {
    font-size: 14px;
    width: 150px;
    height: 35px;
    margin-top: 40px;
    padding-top: 10px;
    margin-left: 210px;
  }
  .h-titles-policy {
    font-size: 22px;
  }
  .table-policy {
    width: 380px;
  }
  .title-policy {
    font-size: 21px;
  }
  .p-content-policy {
    font-size: 15px;
  }
  .title-column {
    font-size: 13px;
  }
  .content-column {
    font-size: 11px;
  }
}
@media (max-width: 466px) {
  h4 {
    font-size: 16px;
  }
  .social-networks-hidden {
    font-size: 16px;
  }
}
@media (max-width: 460px) {
  #input-submit {
    width: 350px;
    height: 50px;
  }
  .row-services {
    margin-top: 30px;
  }
  .border-2 {
    width: 12% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 87%;
    margin-top: -20px;
  }
  .border-6 {
    width: 30%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 70%;
    margin-top: -20px;
  }
}
@media (max-width: 454px) {
  .text-wrap-slider-pages .title-slider-pages {
    margin-left: 30px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    margin-left: 30px;
  }
  .border-slider-about {
    width: 325px;
    margin-left: 30px;
  }
  .border-slider-services {
    width: 330px;
    margin-left: 30px;
  }
  .border-slider-ciber {
    width: 340px;
    margin-left: 30px;
  }
  .border-slider-blog {
    width: 260px;
    margin-left: 30px;
  }
  .border-slider-contact {
    width: 340px;
    margin-left: 30px;
  }
}
@media (max-width: 450px) {
  .container-fluid-offer {
    height: 700px;
  }
  .img-fluid-offer {
    margin-left: 60px;
  }
  .container-fluid-ciber-SGSI {
    height: 1100px;
  }
  .col-img-ciber {
    padding-left: 50px;
  }
  .title-quality {
    font-size: 21px;
  }
  .title-form {
    padding-top: 10px;
    font-size: 18px;
    height: 70px;
  }
  .title-form2 {
    padding-top: 17px;
    font-size: 24px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 15px;
  }
  .container-fluid-attention {
    height: 700px;
  }
  .img-map {
    margin-left: 150px;
    width: 115px;
    margin-top: 10px;
  }
  .h-titles-attention1 {
    font-size: 28px;
  }
  .h-titles-attention2 {
    font-size: 38px;
  }
  .h-titles-attention3 {
    font-size: 28px;
    margin-top: 60px;
  }
  .h-titles-attention4 {
    font-size: 38px;
  }
  .wpp-contact {
    font-size: 22px;
  }
  .mail-contact {
    font-size: 22px;
  }
  .border-8 {
    width: 15%;
    margin-top: -27px;
  }
  .border-9 {
    margin-left: 85%;
    margin-top: -20px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    margin-left: 30px;
  }
  .text-wrap-slider .p-slider-pages {
    margin-left: 30px;
  }
  .btn-offer {
    margin-top: 30px;
    margin-left: 135px;
  }
  .container-fluid-services-form {
    height: 380px;
  }
  .container-diagnostic-form {
    margin-left: 45px;
  }
  .container-content-policy {
    margin-left: 20px;
    margin-right: 20px;
  }
  .h-titles-policy {
    font-size: 21px;
  }
  .table-policy {
    width: 350px;
  }
  .title-policy {
    font-size: 20px;
  }
  .p-content-policy {
    font-size: 14px;
  }
  .title-column {
    font-size: 12px;
  }
  .content-column {
    font-size: 10px;
  }
}
@media (max-width: 430px) {
  .container-fluid-offer {
    height: 700px;
  }
  .text-wrap-slider {
    width: 80%;
  }
  .text-wrap-slider .title-slider-home {
    font-size: 24px;
    margin-left: 80px;
    margin-top: 70px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 24px;
    margin-left: 80px;
    margin-top: 70px;
  }
  .text-wrap-slider-pages .title-slider-pages {
    font-size: 24px;
    margin-left: 30px;
    margin-top: 70px;
  }
  .text-wrap-slider .p-slider-home {
    font-size: 16px;
    margin-left: 80px;
  }
  .text-wrap-slider-pages .p-slider-pages {
    font-size: 16px;
    margin-left: 30px;
  }
  .border-slider-home {
    margin-left: 80px;
    width: 150px;
  }
  .btn-slider {
    position: absolute;
    top: 260px;
    left: 80px;
  }
  .container-fluid-company {
    height: 450px;
  }
  .row-company {
    margin-top: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
  .img-company {
    width: 280px;
    margin-left: 40px;
  }
  .p-about-us {
    font-size: 16px;
    margin-top: 0;
  }
  .btn-outline-success-2 {
    font-size: 17px;
  }
  .img-ciber {
    width: 230px;
  }
  .col-img-ciber {
    padding-left: 50px;
  }
  #input-submit {
    width: 323px;
    height: 50px;
  }
  .container-diagnostic-form {
    margin-left: 30px;
  }
  h4 {
    font-size: 15px;
  }
  .social-networks-hidden {
    font-size: 15px;
  }
  .icons-social-networks {
    width: 40px;
  }
  .icons-social-networks2 {
    width: 40px;
  }
}
@media (max-width: 420px) {
  .border-slider-ciber {
    width: 325px;
  }
}
@media (max-width: 418px) {
  .border-slider-contact {
    width: 225px;
  }
}
@media (max-width: 414px) {
  .container-fluid-offer {
    height: 700px;
  }
  .img-fluid-offer {
    margin-left: 45px;
  }
  .btn-offer {
    margin-top: 30px;
    margin-left: 115px;
  }
  .col-text-offer {
    flex: 1 0;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -30px;
    padding: 0;
  }
  .h-titles {
    font-size: 34px;
  }
  .h-titles-ciber {
    font-size: 29px;
  }
  .h-titles-ciber-SGSI2 {
    font-size: 29px;
  }
  .border-2 {
    width: 11% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 88%;
    margin-top: -20px;
  }
  .col-img-consult {
    width: 300px;
    padding-left: 80px;
    margin-bottom: 30px;
  }
  .img-consult {
    width: 230px;
  }
  .p-consult {
    margin-top: 30px;
    margin-right: 0px;
    font-size: 18px;
    text-align: justify;
    font-weight: 400;
    margin: 0;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 100px;
  }
  .text-wrap-service {
    font-size: 16px;
    width: 240px;
    height: 100%;
    font-weight: 500;
    margin-top: 50px;
  }
  .p-services {
    padding-top: 280px;
    text-align: justify;
    height: 390px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 140px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .border-8 {
    width: 13%;
    margin-top: -27px;
  }
  .border-9 {
    margin-left: 87%;
    margin-top: -20px;
  }
  h4 {
    font-size: 14px;
  }
  .social-networks-hidden {
    font-size: 14px;
  }
  .icons-social-networks {
    width: 35px;
  }
  .icons-social-networks2 {
    width: 35px;
  }
  .container-fluid-company {
    height: 430px;
  }
  .row-company {
    margin-top: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 10px;
    margin-right: 10px;
    width: auto;
  }
  .img-company {
    width: 270px;
    margin-left: 30px;
  }
  .p-about-us {
    font-size: 15px;
    margin-top: 0;
  }
  .border-4 {
    width: 15%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 85%;
    margin-top: -20px;
  }
  .border-6 {
    width: 28%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 72%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
  }
  .container-fluid-mission-vision {
    height: 900px;
  }
  .col-vision {
    margin-top: 10px;
  }
  .text-wrap-mission {
    width: 320px;
    height: 420px;
    margin-top: -50px;
  }
  .text-wrap-vision {
    width: 320px;
    height: 420px;
    margin-top: -50px;
  }
  .text-wrap-mission .values {
    font-size: 24px;
    margin-top: 150px;
  }
  .text-wrap-vision .values {
    font-size: 24px;
    margin-top: 150px;
  }
  .text-wrap-mission .p-m-v {
    width: 292px;
    margin-left: 10px;
  }
  .text-wrap-vision .p-m-v {
    width: 292px;
    margin-left: 10px;
  }
  .row-infraestructure {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
  }
  .container-fluid-innova {
    height: 830px;
  }
  .row-innovation2 {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 30px;
    margin-left: 30px;
  }
  .container-fluid-air {
    height: 800px;
  }
  .border-14 {
    width: 17%;
    margin-top: -29px;
  }
  .border-15 {
    margin-left: 83%;
    margin-top: -20px;
  }
  .border-16 {
    width: 25%;
    margin-top: -28px;
  }
  .border-17 {
    margin-left: 75%;
    margin-top: -20px;
  }
  .container-fluid-networks {
    height: 800px;
  }
  .col-img-ciber {
    padding-left: 40px;
  }
  .container-fluid-ciber-quality {
    height: 1050px;
  }
  .p-ciber-quality {
    width: 300px;
  }
  .title-form {
    padding-top: 10px;
    font-size: 18px;
    height: 60px;
  }
  .title-form2 {
    padding-top: 18px;
    font-size: 23px;
  }
  .soon2 {
    margin-top: 20px;
    font-size: 16px;
  }
  .container-fluid-attention {
    height: 680px;
  }
  .img-map {
    margin-left: 120px;
    width: 115px;
    margin-top: 10px;
  }
  .h-titles-attention1 {
    font-size: 25px;
  }
  .h-titles-attention2 {
    font-size: 35px;
  }
  .h-titles-attention3 {
    font-size: 25px;
    margin-top: 50px;
  }
  .h-titles-attention4 {
    font-size: 35px;
  }
  .wpp-contact {
    font-size: 22px;
  }
  .mail-contact {
    font-size: 22px;
  }
  #input-submit {
    width: 308px;
  }
  .nav-toggle {
    left: 82%;
  }
  .container-fluid-services-sp {
    height: 570px;
  }
  .service-networks {
    margin-left: 30px;
  }
  .service-air {
    margin-left: 40px;
  }
  .col-icons {
    width: 125px;
    height: 130px;
  }
  .img-services {
    width: 90px;
  }
  .services-link-infra {
    font-size: 22px;
  }
  .services-link-innova {
    font-size: 22px;
  }
  .services-link-net {
    font-size: 22px;
    margin-left: 15px;
    padding-left: 0;
    padding-right: 0;
  }
  .services-link-air {
    font-size: 22px;
    padding-left: 10px;
    padding-right: 20px;
  }
  .container-fluid-services-form {
    height: 380px;
  }
  .container-diagnostic-form {
    margin-left: 60px;
    width: 293px;
    height: 170px;
    margin-top: -10px;
  }
  .soon1 {
    font-size: 15px;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .btn-diagnostic-form {
    font-size: 13px;
    width: 140px;
    height: 35px;
    margin-top: 35px;
    padding-top: 10px;
    margin-left: 155px;
  }
  .h-titles-policy {
    font-size: 20px;
  }
  .table-policy {
    width: 320px;
  }
  .title-policy {
    font-size: 18px;
  }
  .p-content-policy {
    font-size: 13px;
  }
  .title-column {
    font-size: 11px;
  }
  .content-column {
    font-size: 10px;
  }
  .footer-copyright {
    font-size: 20px;
  }
  .links-politics {
    font-size: 14px;
  }
}
@media (max-width: 409px) {
  .border-slider-services {
    width: 295px;
  }
}
@media (max-width: 404px) {
  .border-slider-about {
    width: 295px;
  }
}
@media (max-width: 400px) {
  .border-2 {
    width: 9% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 91%;
    margin-top: -20px;
  }
  .border-6 {
    width: 26%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 74%;
    margin-top: -20px;
  }
  .border-8 {
    width: 11%;
    margin-top: -27px;
  }
  .border-9 {
    margin-left: 89%;
    margin-top: -20px;
  }
  .border-slider-ciber {
    width: 175px;
  }
}
@media (max-width: 391px) {
  .container-fluid-offer {
    height: 740px;
  }
  .img-fluid-offer {
    margin-left: 34px;
  }
  .btn-offer {
    margin-top: 20px;
    margin-left: 115px;
  }
}
@media (max-width: 390px) {
  .border-2 {
    width: 8% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 92%;
    margin-top: -20px;
  }
  .text-wrap-slider .p-slider-pages {
    font-size: 15px;
    margin-right: 0;
  }
}
@media (max-width: 395px) {
  #input-submit {
    width: 292px;
  }
  .h-titles-policy {
    font-size: 19px;
  }
  .table-policy {
    width: 300px;
  }
  .title-policy {
    font-size: 17px;
  }
}
@media (max-width: 380px) {
  .img-fluid-offer {
    margin-left: 30px;
  }
  .btn-offer {
    margin-top: 20px;
    margin-left: 105px;
  }
  .h-titles {
    font-size: 34px;
  }
  .border-2 {
    width: 7% !important;
    margin-top: -30px;
  }
  .border-3 {
    margin-left: 93%;
    margin-top: -20px;
  }
  .text-wrap-service {
    font-size: 16px;
    width: 235px;
    height: 100%;
    font-weight: 500;
    margin-top: 50px;
  }
  .p-services {
    padding-top: 270px;
    text-align: justify;
    height: 375px;
  }
  .btn-link {
    margin-top: -30px;
    margin-left: 140px;
    font-weight: 500;
    color: black;
    text-align: center;
    justify-content: end;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 15px;
    border-radius: 0.5rem;
    background-color: #fff;
  }
  .col-img-consult {
    width: 300px;
    padding-left: 80px;
    margin-bottom: 30px;
  }
  .img-consult {
    width: 200px;
  }
  .btn-consult {
    color: white;
    background-color: #0082c6;
    font-weight: 500;
    font-size: 15px;
    width: 180px;
    height: 30px;
    margin-top: 5px;
    padding-top: 5px;
    margin-left: 90px;
  }
  .border-8 {
    width: 9%;
    margin-top: -27px;
  }
  .border-9 {
    margin-left: 91%;
    margin-top: -20px;
  }
  .row-company {
    margin-top: 30px;
  }
  .col-company {
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }
  .img-company {
    width: 270px;
    margin-left: 10px;
  }
  .p-about-us {
    font-size: 15px;
    margin-top: 0;
  }
  .border-6 {
    width: 24%;
    margin-top: -30px;
  }
  .border-7 {
    margin-left: 76%;
    margin-top: -20px;
  }
  .border-4 {
    width: 10%;
    margin-top: -30px;
  }
  .border-5 {
    margin-left: 90%;
    margin-top: -20px;
  }
  .h-values-title {
    color: #0082c6;
    text-align: center;
    margin-top: 40px;
    font-size: 24px;
  }
  .container-fluid-values {
    width: 100%;
    height: 1120px;
    padding-top: 30px;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
    background-color: #d9d9d9;
  }
  .row-values {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    height: 900px;
  }
  .text-wrap-values {
    font-size: 14px;
    text-align: left;
    width: 75%;
    height: 100%;
    font-weight: 500;
  }
  .h-values {
    color: #fff;
    text-align: center;
    margin-top: 30px;
    margin-left: 100px;
    font-size: 23px;
  }
  .p-values {
    padding-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    text-align: justify;
  }
  .col-value1 {
    background-size: 280px;
    margin-bottom: 30px;
  }
  .col-value2 {
    background-size: 280px;
    margin-bottom: 30px;
  }
  .col-value3 {
    background-size: 280px;
    margin-bottom: 30px;
  }
  .border-14 {
    width: 15%;
    margin-top: -29px;
  }
  .border-15 {
    margin-left: 85%;
    margin-top: -20px;
  }
  .border-16 {
    width: 23%;
    margin-top: -28px;
  }
  .border-17 {
    margin-left: 77%;
    margin-top: -20px;
  }
  .container-fluid-mission-vision {
    height: 770px;
  }
  .row-m-v {
    height: 800px;
  }
  .col-vision {
    margin-top: -10px;
  }
  .text-wrap-mission {
    width: 280px;
    height: 400px;
    margin-top: -80px;
    font-size: 14px;
  }
  .text-wrap-vision {
    width: 280px;
    height: 400px;
    margin-top: -80px;
    font-size: 14px;
  }
  .text-wrap-mission .values {
    font-size: 22px;
  }
  .text-wrap-vision .values {
    font-size: 22px;
  }
  .text-wrap-mission .p-m-v {
    width: 254px;
    margin-left: 10px;
  }
  .text-wrap-vision .p-m-v {
    width: 254px;
    margin-left: 10px;
  }
  .container-fluid-infra {
    height: 800px;
  }
  .container-fluid-innova {
    height: 850px;
  }
  .container-fluid-networks {
    height: 800px;
  }
  .container-fluid-ciber-SGSI {
    height: 1150px;
  }
  .img-ciber {
    width: 215px;
  }
  .col-img-ciber {
    padding-left: 40px;
  }
  .p-ciber-quality {
    width: 300px;
    margin-left: 10px;
  }
  .container-fluid-ciber-specialized {
    height: 930px;
  }
  .title-quality {
    font-size: 20px;
  }
  .container-fluid-attention {
    height: 650px;
  }
  .img-map {
    margin-left: 110px;
    width: 110px;
    margin-top: 10px;
  }
  .h-titles-attention1 {
    font-size: 22px;
  }
  .h-titles-attention2 {
    font-size: 32px;
  }
  .h-titles-attention3 {
    font-size: 22px;
    margin-top: 40px;
  }
  .h-titles-attention4 {
    font-size: 32px;
  }
  .wpp-contact {
    font-size: 21px;
  }
  .mail-contact {
    font-size: 21px;
  }
  .title-form2 {
    padding-top: 20px;
    font-size: 22px;
  }
  #input-submit {
    width: 278px;
  }
  .text-wrap-slider .title-slider-home3 {
    font-size: 24px;
    margin-left: 80px;
    margin-top: 50px;
  }
  .container-diagnostic-form {
    margin-left: 40px;
  }
}
@media (max-width: 372px) {
  .h-titles-policy {
    font-size: 18px;
  }
  h4 {
    font-size: 13px;
  }
}
@media (max-width: 360px) {
  .container-fluid-ciber-specialized {
    height: 970px;
  }
  .container-diagnostic-form {
    margin-left: 30px;
  }
}
@media (max-width: 350px) {
  .h-titles-policy {
    font-size: 17px;
  }
  .title-policy {
    font-size: 16px;
  }
  h4 {
    font-size: 12px;
  }
  .social-networks-hidden {
    font-size: 12px;
  }
  .table-policy {
    width: 270px;
  }
  .title-column {
    font-size: 10px;
  }
  .content-column {
    font-size: 9px;
  }
  .icons-social-networks {
    width: 32px;
  }
  .icons-social-networks2 {
    width: 32px;
  }
}
/*# sourceMappingURL=bootstrap.min.css.map */
