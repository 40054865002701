.container-fluid-blog {
  width: 100%;
  height: auto;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}
.container-post {
  height: auto;
  box-shadow: 0px 0px 10px 2px gray;
  border-radius: 50px;
  margin-left: 200px;
  margin-right: 200px;
  margin-top: 100px;
  margin-bottom: 100px;
}
.container-img-post {
  display: flex;
  justify-content: center;
}
.img-post {
  width: 500px;
  margin-top: 50px;
  border-radius: 50px;
}
.category-date {
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 50px;
}
.category {
  font-size: 24px;
  color: #20c997;
}
.date {
  font-size: 24px;
}
.content-post {
  margin-left: 100px;
  margin-right: 100px;
  margin-top: 30px;
}
.title-post {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 50px;
  padding-top: 20px;
}
.subtitle-post {
  font-size: 30px;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 50px;
}
.p-content {
  font-size: 23px;
  width: auto;
  text-align: justify;
}
.blog-list {
  font-size: 23px;
  width: auto;
  text-align: justify;
}
.blog-list-item {
  gap: 10px;
}
.links-posts {
  color: #0082c6;
  font-weight: 500;
}
.links-blog {
  color: #09294f;
  font-weight: 500;
}
.list-politics {
  padding-bottom: 20px;
}
.subtitles-post {
  padding-top: 30px;
  font-size: 26px;
  font-weight: 500;
}
.img-complementary {
  height: 400px;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
blockquote {
  display: flex;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  font-style: italic;
  font-weight: 500;
  margin-bottom: 50px;
}
.container-img-complementary {
  display: flex;
  justify-content: center;
  padding-top: 30px;
}
.list-data {
  list-style: none;
}

@media (max-width: 1580px) {
  .p-content {
    font-size: 18px;
  }
  .blog-list {
    font-size: 18px;
  }
  .title-post {
    font-size: 32px;
  }
  .subtitle-post {
    font-size: 26px;
  }
  .subtitles-post {
    font-size: 24px;
  }
  .category {
    font-size: 22px;
  }
  .date {
    font-size: 22px;
  }
  .img-post {
    width: 450px;
  }
  .container-post {
    margin-left: 170px;
    margin-right: 170px;
  }
}
@media (max-width: 1190px) {
  .p-content {
    font-size: 16px;
  }
  .blog-list {
    font-size: 16px;
  }
  .title-post {
    font-size: 30px;
  }
  .subtitle-post {
    font-size: 25px;
  }
  .category {
    font-size: 20px;
  }
  .date {
    font-size: 20px;
  }
  .img-post {
    margin-top: 40px;
    width: 430px;
  }
  .container-post {
    margin-left: 130px;
    margin-right: 130px;
  }
}
@media (max-width: 1020px) {
  .p-content {
    font-size: 15px;
  }
  .blog-list {
    font-size: 15px;
  }
  .title-post {
    font-size: 28px;
  }
  .subtitle-post {
    font-size: 24px;
  }
  .category {
    font-size: 19px;
  }
  .date {
    font-size: 19px;
  }
  .img-post {
    width: 400px;
  }
  .container-post {
    margin-left: 100px;
    margin-right: 100px;
  }
  .img-complementary {
    height: 350px;
  }
}
@media (max-width: 830px) {
  .p-content {
    font-size: 14px;
  }
  .blog-list {
    font-size: 14px;
  }
  .title-post {
    font-size: 24px;
  }
  .subtitle-post {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .category {
    font-size: 18px;
  }
  .date {
    font-size: 18px;
  }
  .img-post {
    width: 370px;
  }
  .container-post {
    margin-left: 80px;
    margin-right: 80px;
  }
  .content-post {
    margin-left: 70px;
    margin-right: 70px;
    margin-top: 30px;
  }
}
@media (max-width: 720px) {
  .title-post {
    font-size: 22px;
  }
  .subtitle-post {
    font-size: 18px;
  }
  .category {
    font-size: 16px;
  }
  .date {
    font-size: 16px;
  }
  .img-post {
    margin-top: 30px;
    width: 350px;
  }
  .img-complementary {
    height: 300px;
  }
  .content-post {
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
  }
}
@media (max-width: 650px) {
  .title-post {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .subtitle-post {
    font-size: 17px;
  }
  .category {
    font-size: 14px;
  }
  .date {
    font-size: 14px;
  }
  .img-post {
    width: 330px;
  }
  .container-img-complementary {
    padding-top: 0;
  }
  .img-complementary {
    height: 280px;
  }
  .container-post {
    margin-left: 60px;
    margin-right: 60px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .content-post {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 30px;
  }
  blockquote {
    margin-bottom: 30px;
    margin-top: 0;
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .img-post {
    width: 300px;
  }
  .img-complementary {
    height: 250px;
  }
  .category-date {
    height: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
  }
}
@media (max-width: 500px) {
  .img-post {
    width: 280px;
  }
  .img-complementary {
    height: 200px;
  }
  blockquote {
    margin: 0;
    margin-bottom: 20px;
  }
}
@media (max-width: 460px) {
  .img-post {
    width: 240px;
  }
  .category-date {
    height: 20px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
  }
}
@media (max-width: 430px) {
  .title-post {
    font-size: 18px;
  }
  .subtitle-post {
    font-size: 16px;
  }
  .category {
    font-size: 11px;
  }
  .date {
    font-size: 11px;
  }
  .img-post {
    width: 220px;
  }
  .container-post {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .img-complementary {
    height: 200px;
    width: 250px;
  }
  .content-post {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }
  .category-date {
    height: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 10px;
  }
}
@media (max-width: 380px) {
  .img-post {
    width: 220px;
  }
  .img-complementary {
    height: 180px;
    width: 230px;
  }
  .container-post {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
}
